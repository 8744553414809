import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  FilterFn,
  RowSelectionState,
  SortingState,
  Table,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import { FilterValue } from "@/components/canister-list/filters/types";
import { columns } from "@/components/canister-list/table/columns";
import { useCanisterTableQuery } from "@/hooks/queries/canisters";
import { CanisterTableData } from "@/lib/insights/canister-insights";

interface CanisterTableStore {
  table: Table<CanisterTableData>;
  setTable: (x: Table<CanisterTableData>) => void;
  sorting: SortingState;
  setSorting: (x: SortingState) => void;
  rowSelection: RowSelectionState;
  setRowSelection: (x: RowSelectionState) => void;
  columnVisibility: VisibilityState;
  setColumnVisibility: (x: VisibilityState) => void;
  filters: FilterValue[];
  setFilters: (x: FilterValue[]) => void;
  filterMode: "matchAll" | "matchAny";
  setFilterMode: (mode: "matchAll" | "matchAny") => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

export const useCanisterTableStore = create<CanisterTableStore>()(
  persist(
    (set) => ({
      table: {} as Table<CanisterTableData>,
      setTable: (table) => set({ table }),
      sorting: [{ id: "Status", desc: true }],
      setSorting: (sorting) => set({ sorting }),
      rowSelection: {},
      setRowSelection: (rowSelection) => set({ rowSelection }),
      columnVisibility: {},
      setColumnVisibility: (columnVisibility) => set({ columnVisibility }),
      filters: [],
      setFilters: (filters) => set({ filters }),
      filterMode: "matchAll",
      setFilterMode: (filterMode) => set({ filterMode }),
      searchQuery: "",
      setSearchQuery: (query) => set({ searchQuery: query }),
    }),
    {
      name: "canister-table-state",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => {
        const store = {
          sorting: state.sorting,
          columnVisibility: state.columnVisibility,
          filters: state.filters,
          filterMode: state.filterMode,
          searchQuery: state.searchQuery,
        };
        return store;
      },
    }
  )
);

function updater<T extends object>(set: (d: T) => void, old: T) {
  return (u: T | ((old: T) => T)) => {
    if (typeof u === "function") {
      set(u(old));
    } else {
      set(u);
    }
  };
}

const customFilterFn: FilterFn<any> = (
  row,
  columnId,
  filterValue: FilterValue
) => {
  const value = row.getValue(columnId);

  switch (filterValue.type) {
    case "multiselect":
      if (!filterValue.values || filterValue.values.length === 0) return true;
      if (filterValue.comparator === "includes all") {
        return filterValue.values.every((v: string) => {
          if (Array.isArray(value)) return value.includes(v);
          return false;
        });
      }
      if (filterValue.comparator === "includes one of") {
        return filterValue.values.some((v: string) => {
          if (Array.isArray(value)) return value.includes(v);
          return false;
        });
      }
      if (filterValue.comparator === "does not include") {
        return filterValue.values.every((v: string) => {
          if (Array.isArray(value)) return !value.includes(v);
          return true;
        });
      }
      return false;
    case "select":
      return filterValue.comparator === "is"
        ? value === filterValue.value
        : value !== filterValue.value;

    case "string":
      const strValue = String(value || "");
      return filterValue.comparator === "is"
        ? strValue.includes(filterValue.value?.toString() || "")
        : !strValue.includes(filterValue.value?.toString() || "");

    case "number":
      const numValue = Number(value);
      let filterNum = Number(filterValue.value);

      if (filterValue.field === "Memory Size") {
        filterNum = filterNum * 1024 * 1024;
      }

      if (filterValue.field === "Balance") {
        filterNum *= 10e12;
      }

      if (
        filterValue.field === "Burn (30d)" ||
        filterValue.field === "Burn (24hr)"
      )
        filterNum *= 10e11;

      switch (filterValue.comparator) {
        case "equals":
          return numValue === filterNum;
        case "greater than":
          return numValue > filterNum;
        case "less than":
          return numValue < filterNum;
        default:
          return true;
      }

    default:
      return false;
  }
};

function useCanisterTable() {
  const canisters = useCanisterTableQuery();
  const data = useMemo(() => canisters.data ?? [], [canisters.data]);
  const { filters, filterMode, searchQuery } = useCanisterTableStore();

  // Filter data based on search query
  const searchFilteredData = useMemo(() => {
    if (!searchQuery) return data;
    const query = searchQuery.toLowerCase();
    return data.filter((canister) => {
      // Search across all relevant fields
      const searchableFields = [
        canister.id.toString(),
        canister.name,
        canister.project,
        canister.tags?.join(" "),
        canister.monitoringMechanism,
      ];
      return searchableFields.some((field) =>
        field?.toString().toLowerCase().includes(query)
      );
    });
  }, [data, searchQuery]);

  // Convert filters to TanStack format
  const columnFilters = useMemo(
    () =>
      filters.map((filter) => ({
        id: filter.field,
        value: filter,
      })),
    [filters]
  );

  const {
    sorting,
    setSorting,
    rowSelection,
    setRowSelection,
    columnVisibility,
    setColumnVisibility,
    setTable,
  } = useCanisterTableStore();

  const table = useReactTable({
    data: searchFilteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    filterFns: {
      custom: customFilterFn,
    },
    onRowSelectionChange: updater(setRowSelection, rowSelection),
    onSortingChange: updater(setSorting, sorting),
    onColumnVisibilityChange: updater(setColumnVisibility, columnVisibility),
    state: {
      sorting,
      rowSelection,
      columnVisibility,
      globalFilter: filters,
      pagination: {
        pageSize: 10_000,
        pageIndex: 0,
      },
    },
    autoResetPageIndex: false,
    globalFilterFn: (row, _, filterValue, addMeta) => {
      if (!filterValue.length) return true;

      const filterResults = filterValue.map((filter: { field: string }) =>
        customFilterFn(row, filter.field, filter, addMeta)
      );

      return filterMode === "matchAll"
        ? filterResults.every(Boolean)
        : filterResults.some(Boolean);
    },
  });

  useEffect(() => {
    setTable(table);
  }, []);

  useEffect(() => {
    if (columnVisibility && Object.keys(columnVisibility).length > 0) return;
    setColumnVisibility(
      table.getAllFlatColumns().reduce((acc, x) => {
        if (!x.id) return acc;
        const visible = x.columnDef.meta?.defaultHidden !== true;
        acc[x.id] = visible;
        return acc;
      }, {} as Record<string, boolean>)
    );
  }, []);

  return table;
}

export { useCanisterTable };
