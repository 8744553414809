import { create } from "zustand";
import { persist } from "zustand/middleware";

interface NotificationState {
  dismissedNotifications: Record<string, boolean>;
  dismissNotification: (id: string) => void;
  isDismissed: (id: string) => boolean;
}

export const useNotificationStore = create<NotificationState>()(
  persist(
    (set, get) => ({
      dismissedNotifications: {},
      dismissNotification: (id) =>
        set((state) => ({
          dismissedNotifications: {
            ...state.dismissedNotifications,
            [id]: true,
          },
        })),
      isDismissed: (id) => get().dismissedNotifications[id] ?? false,
    }),
    {
      name: "cycleops-notifications",
    }
  )
);
