import React from "react";
import { Link, useNavigate } from "react-router-dom";

import Hero from "@/assets/hero.png";
import HeroLight from "@/assets/hero-light.png";
import HeroSmall from "@/assets/hero-small.png";
import HeroSmallLight from "@/assets/hero-small-light.png";
import { App, AppContents, AppFooter, AppHeaderSimple } from "@/components/app";
import { Button } from "@/components/ui/button";
import { useIdp } from "@/state/stores/idp";
import useThemeStore from "@/state/stores/theme";

interface Props {
  children?: React.ReactNode;
}

export default function LandingPage(props: Props) {
  const [isSmall, setIsSmall] = React.useState(
    window?.matchMedia("(max-width: 750px)").matches
  );
  const { theme } = useThemeStore();
  React.useEffect(() => {
    const listener = () =>
      setIsSmall(window?.matchMedia("(max-width: 750px)").matches);
    window?.addEventListener("resize", listener);
    return () => window?.removeEventListener("resize", listener);
  });
  const img = React.useMemo(() => {
    if (isSmall && theme === "dark") return HeroSmall;
    if (isSmall && theme === "light") return HeroSmallLight;
    if (theme === "light") return HeroLight;
    return Hero;
  }, [theme, isSmall]);

  const { connect, connected } = useIdp();
  const [connecting, setConnecting] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (connected) {
      navigate("/app");
    }
  }, [connected]);

  const handleConnect = async () => {
    setConnecting(true);
    await connect();
    setConnecting(false);
    navigate("/app");
  };

  return (
    <App>
      <AppHeaderSimple />
      <AppContents>
        <div className="flex flex-col min-h-full gap-20 px-14 py-10 items-center justify-center">
          <img
            width={isSmall ? 300 : 733}
            height={isSmall ? 648 : 693}
            src={img}
          />
          <Button
            loading={connecting}
            disabled={connecting}
            size="lg"
            onClick={handleConnect}
            className="text-lg h-[62px] w-[200px]"
          >
            Connect
          </Button>
          <ICPTopUpCTA />
        </div>
      </AppContents>
      <AppFooter />
    </App>
  );
}

function ICPTopUpCTA() {
  return (
    <div className="text-center flex flex-col gap-4 pt-10">
      <div className="font-bold">
        Or, looking for the fastest way to top up a canister?
      </div>
      <Link
        to="https://icptopup.com"
        className="flex flex-col items-center gap-4"
      >
        <img src="/icptopup.png" className="max-w-[400px] rounded-md" />
        <div className="text-sm max-w-[400px]">
          CycleOps is great for developer teams, but if you just want to quickly
          top up a canister, try icptopup.com.
        </div>
        <Button size="lg">Top-Up Now</Button>
      </Link>
    </div>
  );
}
