import { toast } from "sonner";

import { Button } from "@/components/ui/button";

export default function ToastTest() {
  return (
    <div className="container py-8 space-y-8">
      <div>
        <h1 className="text-3xl font-bold">Toast Testing</h1>
        <p className="text-muted-foreground mt-2">
          Test different toast variants and their styles
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="flex flex-col gap-4 p-6 border rounded-lg">
          <h2 className="text-xl font-semibold">Default Toasts</h2>
          <Button
            onClick={() => toast("This is a default toast")}
            variant="outline"
          >
            Show Default Toast
          </Button>
          <Button
            onClick={() => toast.success("Operation completed successfully!")}
            variant="outline"
          >
            Show Success Toast
          </Button>
          <Button
            onClick={() => toast.error("Something went wrong!")}
            variant="outline"
          >
            Show Error Toast
          </Button>
          <Button
            onClick={() => toast.info("Here's some information")}
            variant="outline"
          >
            Show Info Toast
          </Button>
        </div>

        <div className="flex flex-col gap-4 p-6 border rounded-lg">
          <h2 className="text-xl font-semibold">Custom Duration</h2>
          <Button
            onClick={() => toast("Quick toast", { duration: 2000 })}
            variant="outline"
          >
            2 Second Toast
          </Button>
          <Button
            onClick={() => toast("Long toast", { duration: 5000 })}
            variant="outline"
          >
            5 Second Toast
          </Button>
          <Button
            onClick={() => toast("Very long toast", { duration: 10000 })}
            variant="outline"
          >
            10 Second Toast
          </Button>
        </div>

        <div className="flex flex-col gap-4 p-6 border rounded-lg">
          <h2 className="text-xl font-semibold">With Actions</h2>
          <Button
            onClick={() =>
              toast("Action required", {
                action: {
                  label: "Undo",
                  onClick: () => console.log("Undo clicked"),
                },
              })
            }
            variant="outline"
          >
            Toast with Action
          </Button>
          <Button
            onClick={() =>
              toast.success("Settings saved", {
                action: {
                  label: "View",
                  onClick: () => console.log("View clicked"),
                },
              })
            }
            variant="outline"
          >
            Success with Action
          </Button>
          <Button
            onClick={() =>
              toast.error("Error occurred", {
                action: {
                  label: "Retry",
                  onClick: () => console.log("Retry clicked"),
                },
              })
            }
            variant="outline"
          >
            Error with Action
          </Button>
        </div>
      </div>
    </div>
  );
}
