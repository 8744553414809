import React from "react";
import { create } from "zustand";

interface ScreenStore {
  width: number;
  setWidth: (width: number) => void;
}

const getInitialWidth = () => {
  if (typeof window === "undefined") return Infinity;
  return window.innerWidth;
};

const useScreenStore = create<ScreenStore>((set) => {
  // Set up the media query listener
  if (typeof window !== "undefined") {
    window.addEventListener("resize", () => {
      set({ width: window.innerWidth });
    });
  }

  return {
    width: getInitialWidth(),
    setWidth: (width: number) => set({ width }),
  };
});

export function useBreakpoint(breakpoint = 768) {
  const width = useScreenStore((state) => state.width);
  return width < breakpoint;
}
