import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useRoute } from "@/hooks/queries/team";

import Page from "./canisters";

export default function Home() {
  const navigate = useNavigate();
  const route = useRoute();

  useEffect(() => {
    navigate(route("/canisters"), { replace: true });
  }, []);

  return <Page />;
}
