import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronDown, Trash, ArrowUpRightSquare } from "lucide-react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { CreateProjectDialog } from "@/components/create-project-dialog";
import { useDeleteProjectDialog } from "@/components/delete-project-dialog";
import { useTransferProjectDialog } from "@/components/transfer-project-dialog";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useProjectsWithMetricsQuery } from "@/hooks/queries/projects";
import { useRoute } from "@/hooks/queries/team";
import { useCreateProjectDialogStore } from "@/hooks/stores/create-project-dialog-store";
import { mapTrillions } from "@/lib/ic-utils";

type Project = NonNullable<
  ReturnType<typeof useProjectsWithMetricsQuery>["data"]
>[number];

export function ProjectList() {
  const projects = useProjectsWithMetricsQuery();
  const { DeleteProjectDialog, openDeleteProject } = useDeleteProjectDialog();
  const { TransferProjectDialog, openTransferProject } =
    useTransferProjectDialog();
  const { open } = useCreateProjectDialogStore();

  const columns = useMemo<ColumnDef<Project>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Project Name",
        cell: ({ row }) => <>{row.original.name}</>,
      },
      {
        accessorKey: "canisters",
        header: "Canisters",
        cell: ({ row }) => (
          <Badge variant="secondary">
            {row.original.canisters.length} canisters
          </Badge>
        ),
      },
      {
        accessorKey: "metrics.totalBurn",
        header: "Total Burn",
        cell: ({ row }) => (
          <div className="font-medium">
            {mapTrillions(Number(row.original.metrics.totalBurn))}
          </div>
        ),
      },
      {
        accessorKey: "metrics.health",
        header: "Health",
        cell: ({ row }) => {
          const { health } = row.original.metrics;
          return (
            <div className="flex gap-2">
              {health.healthy > 0 && (
                <Badge variant="default" className="bg-green-500">
                  {health.healthy} healthy
                </Badge>
              )}
              {health.unhealthy > 0 && (
                <Badge variant="destructive">
                  {health.unhealthy} low balance
                </Badge>
              )}
              {health.frozen > 0 && (
                <Badge variant="secondary" className="bg-blue-500">
                  {health.frozen} frozen
                </Badge>
              )}
              {health.pending > 0 && (
                <Badge variant="outline">{health.pending} pending</Badge>
              )}
            </div>
          );
        },
      },
      {
        id: "actions",
        cell: ({ row }) => {
          const project = row.original;
          return (
            <div
              className="flex justify-end"
              onClick={(e) => e.stopPropagation()}
            >
              <DropdownMenu modal={false}>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    size="sm"
                    className="h-8 px-3 flex items-center gap-2"
                  >
                    Actions
                    <ChevronDown className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    onClick={() => openTransferProject(project)}
                  >
                    <ArrowUpRightSquare className="mr-2 h-4 w-4" />
                    Transfer Project
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => openDeleteProject(project)}
                    className="text-destructive focus:text-destructive"
                  >
                    <Trash className="mr-2 h-4 w-4" />
                    Delete Project
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        },
      },
    ],
    [openDeleteProject, openTransferProject]
  );

  const table = useReactTable({
    data: projects.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const navigate = useNavigate();
  const route = useRoute();

  if (projects.isLoading) {
    return (
      <div className="space-y-4">
        {Array(6)
          .fill(0)
          .map((_, i) => (
            <Skeleton key={i} className="h-16 w-full" />
          ))}
      </div>
    );
  }

  if (!projects.data?.length) {
    return (
      <div className="w-full flex flex-col items-center justify-center padding-4 py-24 min-h-[320px] gap-8">
        <div className="text-7xl">😶‍🌫️</div>
        You don't have any projects yet!
        <Button
          variant="default"
          size="lg"
          className="text-lg py-8 px-12"
          onClick={open}
        >
          Create Project
        </Button>
      </div>
    );
  }

  return (
    <>
      <CreateProjectDialog />
      <DeleteProjectDialog />
      <TransferProjectDialog />
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <ContextMenu key={row.id}>
              <ContextMenuTrigger asChild>
                <TableRow
                  className="hover:bg-muted/50"
                  // onClick={() => navigate(route(`/projects/${row.original.name}`))}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="h-12 py-0">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </ContextMenuTrigger>
              <ContextMenuContent>
                <ContextMenuItem
                  onClick={() => openTransferProject(row.original)}
                >
                  <ArrowUpRightSquare className="mr-2 h-4 w-4" />
                  Transfer Project
                </ContextMenuItem>
                <ContextMenuItem
                  onClick={() => openDeleteProject(row.original)}
                  className="text-destructive focus:text-destructive"
                >
                  <Trash className="mr-2 h-4 w-4" />
                  Delete Project
                </ContextMenuItem>
              </ContextMenuContent>
            </ContextMenu>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
