import type { ComponentProps } from "react";

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from "@/components/ui/sidebar";
import { SlotOutlet } from "@/components/ui/slot-context";

export function FocusSidebar({ ...props }: ComponentProps<typeof Sidebar>) {
  return (
    <Sidebar variant="inset" {...props} collapsible="icon">
      <SidebarHeader>
        <SlotOutlet name="sidebar-header" />
      </SidebarHeader>
      <SidebarContent>
        <SlotOutlet name="sidebar-content" />
      </SidebarContent>
      <SidebarFooter>
        <SlotOutlet name="sidebar-footer" />
      </SidebarFooter>
    </Sidebar>
  );
}
