import { Home } from "lucide-react";
import { ReactNode } from "react";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { AppLink } from "@/hooks/queries/team";

import { SlotOutlet } from "./ui/slot-context";

interface SiteBreadcrumbProps {
  items: {
    title: ReactNode;
    href?: string;
  }[];
}

export function SiteBreadcrumb({ items }: SiteBreadcrumbProps) {
  if (!items.length) return null;
  const lastItem = items[items.length - 1]!;

  return (
    <>
      <div className="md:hidden flex-1 flex justify-center">
        <span className="text-sm">{lastItem.title}</span>
      </div>
      <Breadcrumb className="hidden md:flex flex-1">
        <BreadcrumbList>
          <BreadcrumbItem>
            <AppLink to="/">
              <BreadcrumbLink>
                <Home className="h-4 w-4" />
              </BreadcrumbLink>
            </AppLink>
          </BreadcrumbItem>
          {items.map((item, index) => (
            <>
              <BreadcrumbSeparator key={`site-breadcrumb-separator-${index}`} />
              <BreadcrumbItem key={`site-breadcrumb-item-${index}`}>
                {item.href ? (
                  <AppLink to={item.href}>
                    <BreadcrumbLink>
                      <BreadcrumbPage>{item.title}</BreadcrumbPage>
                    </BreadcrumbLink>
                  </AppLink>
                ) : (
                  <BreadcrumbPage className="cursor-default">
                    {item.title}
                  </BreadcrumbPage>
                )}
              </BreadcrumbItem>
            </>
          ))}
        </BreadcrumbList>
      </Breadcrumb>
    </>
  );
}
