import { useNavigate } from "react-router-dom";

import LayoutFocus, { FocusTopbar } from "@/components/layout/layout-focus";
import { SiteBreadcrumb } from "@/components/site-breadcrumb";
import { Support } from "@/components/support";
import { Button } from "@/components/ui/button";
import { AppLink } from "@/hooks/queries/team";

export class NotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NotFoundError";
  }
}

export default function Error404() {
  const navigate = useNavigate();

  function handleBack() {
    navigate(-1);
  }

  return (
    <LayoutFocus>
      <FocusTopbar>
        <SiteBreadcrumb items={[{ title: "404" }]} />
      </FocusTopbar>
      <div className="flex flex-col justify-center items-center gap-12 w-full h-full">
        <div className="flex flex-col items-center gap-2">
          <div className="text-5xl font-bold ">Page not found</div>
          <div className="text-lg">
            Sorry, there's nothing here. If you need help,{" "}
            <Support>
              <span className="underline cursor-pointer">
                please contact support.
              </span>
            </Support>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 w-full max-w-48">
          <AppLink to="/" className="w-full">
            <Button className="w-full" size="lg">
              Take me home
            </Button>
          </AppLink>
          <Button
            className="w-full"
            size="lg"
            variant="secondary"
            onClick={handleBack}
          >
            Go back
          </Button>
        </div>
      </div>
    </LayoutFocus>
  );
}
