import { Bell } from "lucide-react";
import { useMemo, useState } from "react";

import { TC } from "@/components/tc";
import { TokenAmount } from "@/components/token-amount";
import { Alert } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  SidebarGroup,
  SidebarGroupContent,
  useSidebar,
} from "@/components/ui/sidebar";
import {
  useCustomerNotificationSettingsQuery,
  useCustomerPaymentConfQuery,
} from "@/hooks/queries/customer";
import { useCustomerCyclesLedgerAllowanceQuery } from "@/hooks/queries/ledger-cycles";
import { useCustomerICPBalanceQuery } from "@/hooks/queries/ledger-icp-legacy";
import { AppLink } from "@/hooks/queries/team";
import { cn } from "@/lib/ui-utils";
import { useNotificationStore } from "@/state/stores/notifications";

import ExperimentalUINotification from "../experimental-ui-notification";

interface Notification {
  id: string;
  content: React.ReactNode;
}

function useNotifications(): Notification[] {
  const lowBalanceNotifications = useLowBalanceNotifications();
  const experimentalDismissed = useMemo(
    () => localStorage.getItem("experimental-ui-dismissed") === "true",
    []
  );

  const experimentalNotification = !experimentalDismissed
    ? {
        id: "experimental-ui",
        content: <ExperimentalUINotification />,
      }
    : null;

  return [
    ...lowBalanceNotifications,
    ...(experimentalNotification ? [experimentalNotification] : []),
  ];
}

function useLowBalanceNotifications(): Notification[] {
  const icpBalanceNotification = useCustomerNotificationSettingsQuery();
  const icpBalance = useCustomerICPBalanceQuery();
  const cyclesAllowance = useCustomerCyclesLedgerAllowanceQuery();
  const paymentConf = useCustomerPaymentConfQuery();

  return useMemo(() => {
    const notifications: Notification[] = [];
    const paymentMethod = paymentConf.data
      ? "icp" in paymentConf.data.paymentMethod
        ? "icp"
        : "cycles"
      : undefined;

    if (!icpBalanceNotification.data) return notifications;
    const { notifyOnICPBelow, notifyOnCyclesApprovalBalanceBelow } =
      icpBalanceNotification.data;

    if (
      paymentMethod === "icp" &&
      notifyOnICPBelow &&
      icpBalance.data?.e8s !== undefined &&
      icpBalance.data.e8s < notifyOnICPBelow.e8s
    ) {
      notifications.push({
        id: "low-icp",
        content: <LowICP />,
      });
    }

    if (
      paymentMethod === "cycles" &&
      notifyOnCyclesApprovalBalanceBelow &&
      cyclesAllowance.data?.allowance !== undefined &&
      cyclesAllowance.data.allowance < notifyOnCyclesApprovalBalanceBelow
    ) {
      notifications.push({
        id: "low-cycles",
        content: <LowCycles />,
      });
    }

    return notifications;
  }, [
    icpBalanceNotification.data,
    icpBalance.data,
    cyclesAllowance.data,
    paymentConf.data,
  ]);
}

function NotificationContent({
  showEmptyState = false,
}: {
  showEmptyState?: boolean;
}) {
  const notifications = useNotifications();
  const { isDismissed } = useNotificationStore();
  const activeNotifications = notifications.filter(
    (notification) => !isDismissed(notification.id)
  );

  if (showEmptyState && activeNotifications.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 py-8 text-center text-sm text-muted-foreground">
        <p>No new notifications</p>
      </div>
    );
  }

  if (activeNotifications.length === 0) {
    return null;
  }

  return (
    <div className="space-y-2">
      {activeNotifications.map((notification) => (
        <div key={notification.id}>{notification.content}</div>
      ))}
    </div>
  );
}

function useActiveNotificationCount() {
  const notifications = useNotifications();
  const { isDismissed } = useNotificationStore();
  return notifications.filter((n) => !isDismissed(n.id)).length;
}

function CollapsedNotifications() {
  const [isOpen, setIsOpen] = useState(false);
  const notificationCount = useActiveNotificationCount();

  return (
    <>
      <Button
        variant="ghost"
        size="icon"
        className="h-8 w-8"
        onClick={() => setIsOpen(true)}
      >
        <Bell className="h-4 w-4" />
        {notificationCount > 0 && (
          <span className="absolute right-0 top-0 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-[10px] font-medium text-white">
            {notificationCount}
          </span>
        )}
      </Button>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Notifications</DialogTitle>
          </DialogHeader>
          <NotificationContent showEmptyState />
        </DialogContent>
      </Dialog>
    </>
  );
}

export function AppNotifications() {
  const { state } = useSidebar();
  const isCollapsed = state === "collapsed";

  if (isCollapsed) {
    return (
      <SidebarGroup className="mt-auto">
        <SidebarGroupContent>
          <CollapsedNotifications />
        </SidebarGroupContent>
      </SidebarGroup>
    );
  }

  return (
    <SidebarGroup className="mt-auto min-h-0">
      <SidebarGroupContent className="overflow-y-auto">
        <NotificationContent />
      </SidebarGroupContent>
    </SidebarGroup>
  );
}

function LowCycles() {
  const cyclesAllowance = useCustomerCyclesLedgerAllowanceQuery();
  const notificationSettings = useCustomerNotificationSettingsQuery();

  return (
    <AppNotification className="bg-orange-600/5 border-orange-400/10">
      <AppNotificationTitle>Low Cycles</AppNotificationTitle>
      <AppNotificationDescription>
        Your cycles allowance (
        <TC
          value={Number(cyclesAllowance.data?.allowance ?? 0) / 1e12}
          includeUnit
        />
        ) is below your alert threshold (
        <TC
          value={
            Number(
              notificationSettings.data?.notifyOnCyclesApprovalBalanceBelow ?? 0
            ) / 1e12
          }
          includeUnit
        />{" "}
        ).
      </AppNotificationDescription>
      <AppNotificationLink>
        <AppLink to="/billing/payment-method">Manage Payment Method</AppLink>
      </AppNotificationLink>
    </AppNotification>
  );
}

function LowICP() {
  const icpBalance = useCustomerICPBalanceQuery();
  const notificationSettings = useCustomerNotificationSettingsQuery();

  return (
    <AppNotification className="bg-orange-600/10 border-orange-400/25">
      <AppNotificationTitle>Low ICP</AppNotificationTitle>
      <AppNotificationDescription>
        Your ICP balance (
        <TokenAmount amount={icpBalance.data?.e8s ?? 0n} stripSymbol /> ICP) is
        below your alert threshold (
        <TokenAmount
          amount={notificationSettings.data?.notifyOnICPBelow?.e8s ?? 0n}
          stripSymbol
        />{" "}
        ICP).
      </AppNotificationDescription>
      <AppNotificationLink>
        <AppLink to="/billing/payment-method">Manage Payment Method</AppLink>
      </AppNotificationLink>
    </AppNotification>
  );
}

function AppNotification({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <Alert className={cn("flex flex-col gap-2 p-3 text-xs", className)}>
      {children}
    </Alert>
  );
}

function AppNotificationTitle({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return <div className={cn("text-xs font-bold", className)}>{children}</div>;
}

function AppNotificationDescription({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return <div className={cn("text-xs", className)}>{children}</div>;
}

function AppNotificationLink({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return <div className={cn("text-xs underline", className)}>{children}</div>;
}
