import { getTimeFrameBoundaries, TimeFrame } from "./time-frame";

export enum TimeResolution {
  HOURLY = "hourly",
  SIX_HOURLY = "six_hourly",
  DAILY = "daily",
  WEEKLY = "weekly",
}

export const DEFAULT_TIME_RESOLUTION = TimeResolution.DAILY;

export function normalizeDate(date: Date, resolution: TimeResolution): Date {
  const normalized = new Date(date);
  normalized.setUTCMilliseconds(0);
  normalized.setUTCSeconds(0);
  normalized.setUTCMinutes(0);

  let day: number;
  switch (resolution) {
    case TimeResolution.HOURLY:
      break;
    case TimeResolution.SIX_HOURLY:
      normalized.setUTCHours(Math.floor(normalized.getUTCHours() / 6) * 6);
      break;
    case TimeResolution.DAILY:
      normalized.setUTCHours(0);
      break;
    case TimeResolution.WEEKLY:
      normalized.setUTCHours(0);
      day = normalized.getUTCDay();
      normalized.setUTCDate(normalized.getUTCDate() - day);
      break;
    default:
      throw new Error(`Invalid time resolution: ${resolution}`);
  }

  return normalized;
}

function generateTimePoints(
  start: Date,
  end: Date,
  resolution: TimeResolution
): Date[] {
  const points: Date[] = [];
  const current = normalizeDate(start, resolution);
  let normalizedEnd = normalizeDate(end, resolution);

  // For weekly resolution, extend the end date to include the full week
  if (resolution === TimeResolution.WEEKLY) {
    const nextWeek = new Date(normalizedEnd);
    nextWeek.setUTCDate(nextWeek.getUTCDate() + 7);
    normalizedEnd = nextWeek;
  }

  while (current < normalizedEnd) {
    points.push(new Date(current));

    switch (resolution) {
      case TimeResolution.HOURLY:
        current.setUTCHours(current.getUTCHours() + 1);
        break;
      case TimeResolution.SIX_HOURLY:
        current.setUTCHours(current.getUTCHours() + 6);
        break;
      case TimeResolution.DAILY:
        current.setUTCDate(current.getUTCDate() + 1);
        break;
      case TimeResolution.WEEKLY:
        current.setUTCDate(current.getUTCDate() + 7);
        break;
      default:
        throw new Error(`Invalid time resolution: ${resolution}`);
    }
  }
  points.push(new Date(current)); // Include the last point

  return points;
}

export function groupTimeSeriesData<T>(
  data: [Date, T][],
  resolution: TimeResolution,
  timeFrame: TimeFrame
): Map<string, [Date, T][]> {
  // Find min and max dates
  const [minDate, maxDate] = getTimeFrameBoundaries(timeFrame);

  // Initialize groups
  const groups = new Map<string, [Date, T][]>();

  if (timeFrame.type === "all") {
    // For "all" type, initialize groups based on the data points
    data.forEach(([date]) => {
      const normalizedDate = normalizeDate(date, resolution);
      const groupKey = normalizedDate.toISOString();
      if (!groups.has(groupKey)) {
        groups.set(groupKey, []);
      }
    });
  } else {
    // For date range, generate all time points in the range
    const timePoints = generateTimePoints(minDate, maxDate, resolution);
    timePoints.forEach((date) => {
      groups.set(date.toISOString(), []);
    });
  }

  // Fill in actual data
  data.forEach(([date, value]) => {
    const normalizedDate = normalizeDate(date, resolution);
    const groupKey = normalizedDate.toISOString();
    groups.get(groupKey)!.push([date, value]);
  });

  return groups;
}
