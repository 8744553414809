import { Eye, EyeOff, Filter, Search, Settings2 } from "lucide-react";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { create } from "zustand";

import BlackholeMigrationModal from "@/components/blackhole-migration-modal";
import CanisterTable from "@/components/canister-list";
import { ColumnVisibility } from "@/components/canister-list/display";
import { CanisterListFilters } from "@/components/canister-list/filters";
import { AggregateBurnChart } from "@/components/chart/aggregate-burn";
import { AggregateTopupChart } from "@/components/chart/aggregate-topup";
import { CanisterHealthChart } from "@/components/chart/canister-health";
import { Layout, TopBar } from "@/components/layout/layout-app";
import { SiteBreadcrumb } from "@/components/site-breadcrumb";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { PageActions } from "@/components/ui/page-actions";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { AppLink } from "@/hooks/queries/team";
import {
  useCanisterTable,
  useCanisterTableStore,
} from "@/hooks/stores/canister-table-store";
import { cn } from "@/lib/ui-utils";

interface SummaryVisibilityState {
  isVisible: boolean;
  toggle: () => void;
}

const useSummaryVisibility = create<SummaryVisibilityState>((set) => ({
  isVisible: localStorage.getItem("summaryVisible") !== "false",
  toggle: () =>
    set((state) => {
      const newValue = !state.isVisible;
      localStorage.setItem("summaryVisible", String(newValue));
      return { isVisible: newValue };
    }),
}));

function Summary() {
  const { isVisible, toggle } = useSummaryVisibility();

  return (
    <>
      <div className="hidden md:block">
        <Button
          size="sm"
          variant="outline"
          className="gap-2 bg-muted/0 text-xs"
          onClick={toggle}
        >
          {isVisible ? (
            <>
              <EyeOff className="w-3.5 h-3.5" /> Summary
            </>
          ) : (
            <>
              <Eye className="w-3.5 h-3.5" /> Summary
            </>
          )}
        </Button>
      </div>
      <div className="md:hidden">
        <Sheet>
          <SheetContent>
            <SummaryCharts />
          </SheetContent>
          <SheetTrigger>
            <Button
              variant="outline"
              size="sm"
              className="gap-2 bg-muted/0 text-xs"
            >
              <Eye className="w-3.5 h-3.5" /> Summary
            </Button>
          </SheetTrigger>
        </Sheet>
      </div>
    </>
  );
}

function SummaryCharts({ className }: { className?: string }) {
  const { isVisible } = useSummaryVisibility();

  return (
    <div
      className={cn(
        "flex flex-col md:flex-row gap-4 pb-4",
        className,
        !isVisible && "md:hidden"
      )}
    >
      <CanisterHealthChart className="flex-1" />
      <AggregateTopupChart className="flex-1" />
      <AggregateBurnChart className="flex-1" />
    </div>
  );
}

function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

function CanistersTopBar() {
  const table = useCanisterTable();
  const { isVisible, toggle } = useSummaryVisibility();
  const { setSearchQuery } = useCanisterTableStore();
  const [searchInput, setSearchInput] = useState("");
  const debouncedSearch = useDebounce(searchInput, 300);

  useEffect(() => {
    setSearchQuery(debouncedSearch);
  }, [debouncedSearch, setSearchQuery]);

  return (
    <TopBar>
      <div className="flex items-center gap-6 max-md:w-full">
        <SiteBreadcrumb
          items={[
            {
              title: (
                <>
                  Canisters{" "}
                  <span className="text-muted-foreground text-sm">
                    ({table.getRowCount()})
                  </span>
                </>
              ),
            },
          ]}
        />
        <div className="md:flex hidden items-center gap-2">
          <div className="relative">
            <Search className="absolute left-2 top-1/2 h-3.5 w-3.5 -translate-y-1/2 text-muted-foreground" />
            <Input
              id="canister-search"
              placeholder="Search canisters..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              className="w-[200px] pl-8 h-9 text-xs"
            />
          </div>
          <CanisterListFilters>
            <Button size="sm" variant="outline" className="gap-2 text-xs">
              <Filter className="w-3.5 h-3.5" /> Filter
            </Button>
          </CanisterListFilters>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="md:flex hidden items-center gap-2">
          <ColumnVisibility>
            <Button size="sm" variant="outline" className="gap-2 text-xs">
              <Settings2 className="w-3.5 h-3.5" /> Display
            </Button>
          </ColumnVisibility>
        </div>
        <div className="md:flex hidden">
          <Summary />
        </div>
        <div className="md:flex hidden items-center gap-2">
          <AddCanisterButton />
        </div>
        <div className="md:hidden">
          <PageActions>
            <div className="flex flex-col md:flex-row items-stretch md:items-center gap-2">
              <div className="relative">
                <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
                <Input
                  placeholder="Search canisters..."
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="w-full pl-8"
                />
              </div>
              <div className="flex items-center gap-2">
                <CanisterListFilters>
                  <Button
                    size="sm"
                    variant="ghost"
                    className="gap-2 bg-muted/0"
                  >
                    <Filter className="w-4 h-4" />
                  </Button>
                </CanisterListFilters>
                <Sheet>
                  <SheetTrigger asChild>
                    <Button
                      size="sm"
                      variant="ghost"
                      className="gap-2 bg-muted/0"
                    >
                      <Settings2 className="w-4 h-4" />
                    </Button>
                  </SheetTrigger>
                  <SheetContent>
                    <ColumnVisibility />
                  </SheetContent>
                </Sheet>
                <Button
                  size="sm"
                  variant="ghost"
                  className="gap-2 bg-muted/0"
                  onClick={toggle}
                >
                  {isVisible ? (
                    <EyeOff className="w-4 h-4" />
                  ) : (
                    <Eye className="w-4 h-4" />
                  )}
                </Button>
                <AddCanisterButton />
              </div>
            </div>
          </PageActions>
        </div>
      </div>
    </TopBar>
  );
}

function AddCanisterButton() {
  return (
    <DropdownMenu>
      <div className="flex gap-[1px]">
        <AppLink to="canisters/new">
          <Button size="sm" className="rounded-r-none">
            Add Canister
          </Button>
        </AppLink>
        <DropdownMenuTrigger asChild>
          <Button size="sm" className="rounded-l-none">
            <FaChevronDown />
          </Button>
        </DropdownMenuTrigger>
      </div>
      <DropdownMenuContent>
        <DropdownMenuItem asChild>
          <AppLink to="canisters/new/blackhole">
            Blackhole monitored canister
          </AppLink>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <AppLink to="canisters/new/nns">NNS monitored canister</AppLink>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <AppLink to="canisters/new/sns">SNS monitored canister</AppLink>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <AppLink to="csv-upload-canisters">CSV upload (blackhole)</AppLink>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default function Page() {
  const { isVisible, toggle } = useSummaryVisibility();
  const { setSearchQuery } = useCanisterTableStore();

  return (
    <Layout>
      <CanistersTopBar />
      <SummaryCharts className="hidden md:flex" />
      <div className="border border-border/75 flex flex-1 flex-col gap-4 p-2 md:p-4 pt-0 relative bg-background rounded-lg overflow-hidden">
        <CanisterTable className="h-auto absolute inset-0" />
      </div>
      <BlackholeMigrationModal />
    </Layout>
  );
}
