import { Principal } from "@dfinity/principal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "sonner";

import {
  CanisterConfig,
  Project,
  Result_16,
  ReturnableCanisterSearchMetadata,
} from "common/declarations/cycleops/cycleops.did.d";

import {
  asTeamDefault,
  useActivePrincipalQuery,
  useAsTeamQuery,
} from "@/hooks/queries/team";
import { cyops } from "@/lib/actors";
import { reverseOptional } from "@/lib/ic-utils";
import {
  CanisterTableData,
  RawCanisterResponse,
} from "@/lib/insights/canister-insights";

import { queryClient } from ".";
import {
  refetchCanisters,
  usePaginatedCanistersQuery,
  useCanisterTableQuery,
} from "./canisters";
import { useChargesQuery } from "./transactions";

// Fetch

function fetchProjects({ asTeamPrincipal } = asTeamDefault) {
  return cyops.getProjects({ asTeamPrincipal });
}

export { fetchProjects };

// Query

type UseProjectsResult = ReturnType<typeof useProjectsQuery>;

function useProjectsQuery() {
  const asTeam = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  return useQuery({
    queryKey: ["projects", principal.data?.toText()],
    queryFn: async () => {
      if (!asTeam.data) throw new Error("Unexpected missing asTeamPrincipal");
      return fetchProjects({ ...asTeam.data });
    },
    staleTime: 1000 * 10,
    enabled: asTeam.isFetched && principal.isFetched,
  });
}

function refetchProjects() {
  queryClient.invalidateQueries({ queryKey: ["projects"], refetchType: "all" });
  queryClient.invalidateQueries({
    queryKey: ["canisters-final"],
    refetchType: "all",
  });
  queryClient.invalidateQueries({
    queryKey: ["canisters"],
    refetchType: "all",
  });
}

export type { UseProjectsResult };
export { useProjectsQuery };

// Post

async function postCanisterTags(
  { canisterId, keywordTags }: { canisterId: Principal; keywordTags: string[] },
  asTeam = asTeamDefault
) {
  const call = await cyops.updateCanisterTags({
    canisterId,
    keywordTags,
    ...asTeam,
  });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postBatchCanisterTags(
  request: {
    canisterTagUpdates: { canisterIds: Principal[]; keywordTags: string[] }[];
  },
  asTeam = asTeamDefault
) {
  const call = await cyops.batchUpdateCanisterTags({
    ...request,
    ...asTeam,
  });
  const errors = call
    .filter(
      (x): x is { result: { err: string }; canisterId: Principal } =>
        "err" in x.result
    )
    .map((x) => [x.canisterId, x.result.err] as const);
  const successes = call
    .filter(
      (x): x is { result: { ok: null }; canisterId: Principal } =>
        "ok" in x.result
    )
    .map((x) => x.canisterId);
  return { call, errors, successes };
}

async function postCreateProject(
  { projectName }: { projectName: string },
  asTeam = asTeamDefault
) {
  const call = await cyops.createProject({ projectName, ...asTeam });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postRenameProject(
  {
    projectName,
    newProjectName,
  }: { projectName: string; newProjectName: string },
  asTeam = asTeamDefault
) {
  const call = await cyops.renameProject({
    projectName,
    newProjectName,
    ...asTeam,
  });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postDeleteProject(
  { projectName }: { projectName: string },
  asTeam = asTeamDefault
) {
  const call = await cyops.deleteProject({ projectName, ...asTeam });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postCanisterProject(
  { canisterId, projectName }: { canisterId: Principal; projectName?: string },
  asTeam = asTeamDefault
) {
  const call = await cyops.updateCanisterProject({
    canisterId,
    projectName: reverseOptional(projectName),
    ...asTeam,
  });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

async function postBatchCanisterProject(
  updates: { projectName?: string; canisterIds: Principal[] }[],
  asTeam = asTeamDefault
) {
  const canisterProjectUpdates = updates.map((x) => ({
    projectName: reverseOptional(x.projectName),
    canisterIds: x.canisterIds,
  }));
  const call = await cyops.batchUpdateCanisterProjects({
    canisterProjectUpdates,
    ...asTeam,
  });
  const errors = call
    .filter(
      (x): x is { result: { err: string }; canisterId: Principal } =>
        "err" in x.result
    )
    .map((x) => [x.canisterId, x.result.err] as const);
  const successes = call
    .filter(
      (x): x is { result: { ok: null }; canisterId: Principal } =>
        "ok" in x.result
    )
    .map((x) => x.canisterId);
  return { call, errors, successes };
}

async function postTransferProject(
  {
    projectName,
    newProjectOwner,
  }: { projectName: string; newProjectOwner: Principal },
  asTeam = asTeamDefault
) {
  const call = await cyops.transferProject({
    projectName,
    newProjectOwner,
    ...asTeam,
  });
  if ("err" in call) throw new Error(call.err);
  return call.ok;
}

export {
  postCanisterTags,
  postBatchCanisterTags,
  postCreateProject,
  postRenameProject,
  postDeleteProject,
  postCanisterProject,
  postBatchCanisterProject,
  postTransferProject,
};

// Mutate

function useCanisterTagsMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  const { refetch: principal } = useActivePrincipalQuery();
  return useMutation({
    mutationKey: ["canistersTags"],
    mutationFn: async (request: {
      canisterId: Principal;
      keywordTags: string[];
    }) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      const r = await postCanisterTags(request, data);
      return r;
    },
    onMutate: async (data) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ["canisters"] });

      const { data: activePrincipal } = await principal();

      // Snapshot the previous value
      const previousCanisters = queryClient.getQueryData<RawCanisterResponse[]>(
        ["canisters", activePrincipal]
      );

      // Optimistically update to the new value
      queryClient.setQueryData<CanisterTableData[]>(
        ["canisters-final", activePrincipal?.toText()],
        (old) => {
          if (!old) return [];
          return old.map((d) => {
            if (d.id.toString() !== data.canisterId.toString()) return d;
            return {
              ...d,
              tags: data.keywordTags,
            };
          });
        }
      );

      return { previousCanisters };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(["canisters"], context?.previousCanisters);
      console.error("useCanisterTagsMutation", err);
      toast.error(`Failed to update canister tags: ${err.message}`);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["canisters"] });
      refetchProjects();
    },
  });
}

function useBatchCanisterTagMutation() {
  const canisters = usePaginatedCanistersQuery();
  const principal = useActivePrincipalQuery();
  const asTeam = useAsTeamQuery();
  return useMutation({
    mutationFn: async (request: {
      canisterIds: Principal[];
      tag: string;
      type: "add" | "remove";
    }) => {
      if (!canisters.data) throw new Error("Unexpected missing canisters");

      // Get existing tags for each canister and prepare the batch update
      const canisterUpdates = request.canisterIds
        .map((canisterId) => {
          const existingTags =
            canisters.data.find(
              (c) => c.canisterId.toString() === canisterId.toString()
            )?.metadata?.keywordTags ?? [];

          // Skip if tag already exists for add, or doesn't exist for remove
          if (existingTags?.includes(request.tag) && request.type === "add") {
            return null;
          }

          if (
            !existingTags?.includes(request.tag) &&
            request.type === "remove"
          ) {
            return null;
          }

          const keywordTags =
            request.type === "add"
              ? [...(existingTags || []), request.tag]
              : existingTags?.filter((tag) => tag !== request.tag);

          return { canisterIds: [canisterId], keywordTags };
        })
        .filter(
          (
            update
          ): update is { canisterIds: Principal[]; keywordTags: string[] } =>
            update !== null
        );

      if (canisterUpdates.length === 0) return [];

      const { call, errors, successes } = await postBatchCanisterTags(
        {
          canisterTagUpdates: canisterUpdates,
        },
        asTeam.data
      );

      if (errors.length > 0 && successes.length === 0) {
        toast.error("Failed to update canister tags", {
          description: errors[0]?.[1],
        });
        console.error({ "Failed to update canister tags": errors });
        throw new Error("Failed to update canister tags");
      }

      if (errors.length > 0) {
        console.error({ "Failed to update canister tags": errors });
        toast.error(
          `Failed updating ${errors.length} canisters, ${successes.length} succeeded`
        );
      }

      return call;
    },
    async onMutate(data) {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ["canisters"] });

      // Snapshot the previous value
      const previousCanisters = queryClient.getQueryData<CanisterTableData[]>([
        "canisters-final",
        principal.data?.toText(),
      ]);

      console.log(
        ["canisters-final", principal.data?.toText()],
        previousCanisters
      );

      // Optimistically update to the new value
      queryClient.setQueryData<CanisterTableData[]>(
        ["canisters-final", principal.data?.toText()],
        (old) => {
          if (!old) return [];
          return old.map((d) => {
            if (
              !data.canisterIds.some((id) => id.toString() === d.id.toString())
            )
              return d;

            const existingTags = d.tags || [];

            // Skip if tag already exists for add, or doesn't exist for remove
            if (existingTags.includes(data.tag) && data.type === "add") {
              return d;
            }
            if (!existingTags.includes(data.tag) && data.type === "remove") {
              return d;
            }

            const newTags =
              data.type === "add"
                ? [...existingTags, data.tag]
                : existingTags.filter((tag) => tag !== data.tag);

            return {
              ...d,
              tags: newTags,
            };
          });
        }
      );

      return { previousCanisters };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(
        ["canisters-final", principal.data?.toText()],
        context?.previousCanisters
      );
      console.error("useBatchCanisterTagMutation", err);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["canisters"] });
      refetchProjects();
    },
    onSuccess: () => {
      toast.success("Canister tags updated successfully");
    },
  });
}

function useDeleteProjectMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  const { refetch: principal } = useActivePrincipalQuery();
  return useMutation({
    mutationFn: async (request: { projectName: string }) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postDeleteProject(request, data);
    },
    onSettled: () => {
      refetchProjects();
    },
    async onMutate(data) {
      const { data: p } = await principal();
      const queryKey = ["projects", p?.toText()];
      queryClient.cancelQueries({ queryKey });
      const previous = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (old?: Project[]) => {
        if (!old) return [];
        return old.filter((pr) => pr.name !== data.projectName);
      });
      return { previous };
    },
    async onError(error, variables, context) {
      const { data: p } = await principal();
      if (context?.previous) {
        queryClient.setQueryData(["projects", p?.toText()], context.previous);
      }
      console.error("useDeleteProjectMutation", error);
      toast.error(`Failed to delete project: ${error.message}`);
    },
  });
}

function useCreateProjectMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  const { refetch } = useActivePrincipalQuery();
  return useMutation({
    mutationFn: async (request: { projectName: string }) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postCreateProject(request, data);
    },
    async onMutate(data) {
      const { data: principal } = await refetch();
      const queryKey = ["projects", principal?.toText()];
      queryClient.cancelQueries({ queryKey });
      const previous = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (old?: Project[]) => {
        if (!old) return [];
        const id = BigInt(Date.now());
        return [
          ...old,
          {
            createdTimestamp: id,
            id: `${id}`,
            name: data.projectName,
            owner: Principal.anonymous(),
          } satisfies Project,
        ];
      });
      return { previous };
    },
    onSettled: () => {
      refetchProjects();
    },
    async onError(error, variables, context) {
      const { data: principal } = await refetch();
      if (context?.previous) {
        queryClient.setQueryData(
          ["projects", principal?.toText()],
          context.previous
        );
      }
      console.error("useCreateProjectMutation", error);
      toast.error(`Failed to create project: ${error.message}`);
    },
  });
}

function useRenameProjectMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  const { refetch: principal } = useActivePrincipalQuery();
  return useMutation({
    mutationFn: async (request: {
      projectName: string;
      newProjectName: string;
    }) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postRenameProject(request, data);
    },
    onSettled: () => {
      refetchProjects();
    },
    async onMutate(data) {
      const { data: p } = await principal();
      const queryKey = ["projects", p?.toText()];
      queryClient.cancelQueries({ queryKey });
      const previous = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (old?: Project[]) => {
        if (!old) return [];
        return old.map((pr) =>
          pr.name === data.projectName
            ? { ...pr, name: data.newProjectName }
            : pr
        );
      });

      const queryKeyCanisters = ["canisters", p];
      queryClient.cancelQueries({ queryKey: queryKeyCanisters });
      const previousCanisters = queryClient.getQueryData(queryKeyCanisters);
      queryClient.setQueryData(
        queryKeyCanisters,
        (
          old?: [
            Principal,
            CanisterConfig,
            [bigint, Result_16][],
            [] | [ReturnableCanisterSearchMetadata]
          ][]
        ) => {
          if (!old) return [];
          return old.map((canister) =>
            canister[3][0]?.projectName[0] === data.projectName
              ? [
                  canister[0],
                  canister[1],
                  canister[2],
                  [{ ...canister[3][0], projectName: [data.newProjectName] }],
                ]
              : canister
          );
        }
      );
      return { previous, previousCanisters };
    },
    async onError(error, variables, context) {
      const { data: p } = await principal();
      if (context?.previous && context?.previousCanisters) {
        queryClient.setQueryData(["projects", p?.toText()], context.previous);
        queryClient.setQueryData(["canisters", p], context.previousCanisters);
      }
      console.error("useRenameProjectMutation", error);
      toast.error(`Failed to rename project: ${error.message}`);
    },
  });
}

function useCanisterProjectMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  const { refetch: principal } = useActivePrincipalQuery();
  return useMutation({
    mutationFn: async (request: {
      canisterId: Principal;
      projectName?: string;
    }) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postCanisterProject(request, data);
    },
    onSettled: () => {
      refetchProjects();
    },
    async onMutate(data) {
      const { data: p } = await principal();
      const queryKey = ["canisters", p];
      queryClient.cancelQueries({ queryKey });
      const previous = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(
        queryKey,
        (
          old?: [
            Principal,
            CanisterConfig,
            [bigint, Result_16][],
            [] | [ReturnableCanisterSearchMetadata]
          ][]
        ) => {
          if (!old) return [];
          return old.map((canister) =>
            canister[0].toText() === data.canisterId.toText()
              ? [
                  canister[0],
                  canister[1],
                  canister[2],
                  canister[3].length
                    ? [{ ...canister[3][0], projectName: [data.projectName] }]
                    : canister[3],
                ]
              : canister
          );
        }
      );
      return { previous };
    },
    async onError(error, variables, context) {
      const { data: p } = await principal();
      if (context?.previous) {
        queryClient.setQueryData(["canisters", p], context.previous);
      }
      console.error("useCanisterProjectMutation", error);
      toast.error(`Failed to update canister project: ${error.message}`);
    },
    onSuccess() {
      toast.success("Canister project updated successfully");
    },
  });
}

function useSingleCanisterProjectMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  const principal = useActivePrincipalQuery();
  return useMutation({
    mutationFn: async (request: {
      canisterId: Principal;
      projectName?: string;
    }) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postCanisterProject(request, data);
    },
    onSettled: () => {
      refetchProjects();
    },
    async onMutate(data) {
      queryClient.cancelQueries({ queryKey: ["canisters", principal.data] });
      const previous = queryClient.getQueryData([
        "canisters-final",
        principal.data?.toText(),
      ]);
      queryClient.setQueryData(
        ["canisters-final", principal.data?.toText()],
        (old?: CanisterTableData[]) => {
          if (!old) return [];
          return old.map((d) => {
            if (d.id.toString() !== data.canisterId.toString()) return d;
            return {
              ...d,
              project: data.projectName,
            };
          });
        }
      );
      return { previous };
    },
    async onError(error, variables, context) {
      if (context?.previous) {
        queryClient.setQueryData(
          ["canisters-final", principal.data?.toText()],
          context.previous
        );
      }
      console.error("useCanisterProjectMutation", error);
      toast.error(`Failed to update canister project: ${error.message}`);
    },
  });
}

function useBatchCanisterProjectMutation() {
  const principal = useActivePrincipalQuery();
  const asTeam = useAsTeamQuery();

  return useMutation({
    mutationFn: async (request: {
      canisterIds: Principal[];
      projectName?: string;
    }) => {
      const { call, errors, successes } = await postBatchCanisterProject(
        [
          {
            projectName: request.projectName,
            canisterIds: request.canisterIds,
          },
        ],
        asTeam.data
      );

      if (errors.length > 0 && successes.length === 0) {
        toast.error("Failed to update canister projects", {
          description: errors[0]?.[1],
        });
        console.error({ "Failed to update canister projects": errors });
        throw new Error("Failed to update canister projects");
      }

      if (errors.length > 0) {
        console.error({ "Failed to update canister projects": errors });
        toast.error(
          `Failed updating ${errors.length} canisters, ${successes.length} succeeded`
        );
      }

      return call;
    },
    async onMutate(data) {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ["canisters"] });

      // Snapshot the previous value
      const previousCanisters = queryClient.getQueryData<RawCanisterResponse[]>(
        ["canisters", principal.data]
      );

      // Optimistically update to the new value
      queryClient.setQueryData<CanisterTableData[]>(
        ["canisters-final", principal.data?.toText()],
        (old) => {
          if (!old) return [];
          return old.map((d) => {
            if (
              !data.canisterIds.some((id) => id.toString() === d.id.toString())
            )
              return d;
            return {
              ...d,
              project: data.projectName,
            };
          });
        }
      );

      return { previousCanisters };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(["canisters"], context?.previousCanisters);
      console.error("useBatchCanisterProjectMutation", err);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["canisters"] });
      refetchProjects();
    },
    onSuccess: () => {
      toast.success("Canister projects updated successfully");
    },
  });
}

function useTransferProjectMutation() {
  const { refetch: asTeam } = useAsTeamQuery();
  const { refetch: principal } = useActivePrincipalQuery();
  return useMutation({
    mutationFn: async (request: {
      projectName: string;
      newProjectOwner: Principal;
    }) => {
      const { data } = await asTeam();
      if (!data) throw new Error("Unexpected missing asTeamPrincipal");
      return postTransferProject(request, data);
    },
    onSettled: () => {
      refetchProjects();
      refetchCanisters();
    },
    async onMutate(data) {
      const { data: p } = await principal();
      const queryKey = ["projects", p?.toText()];
      queryClient.cancelQueries({ queryKey });
      const previous = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (old?: Project[]) => {
        if (!old) return [];
        return old.filter((pr) => pr.name !== data.projectName);
      });

      const queryKeyCanisters = ["canisters", p];
      queryClient.cancelQueries({ queryKey: queryKeyCanisters });
      const previousCanisters = queryClient.getQueryData(queryKeyCanisters);
      queryClient.setQueryData(
        queryKeyCanisters,
        (
          old?: [
            Principal,
            CanisterConfig,
            [bigint, Result_16][],
            [] | [ReturnableCanisterSearchMetadata]
          ][]
        ) => {
          if (!old) return [];
          return old.filter(
            (canister) => canister[3][0]?.projectName[0] !== data.projectName
          );
        }
      );
      return { previous, previousCanisters };
    },
    onError: (error) => {
      console.error("useTransferProjectMutation", error);
      toast.error(`Failed to transfer project: ${error.message}`);
    },
  });
}

interface ProjectWithMetrics {
  id: string;
  name: string;
  owner: Principal;
  createdTimestamp: bigint;
  canisters: CanisterTableData[];
  metrics: {
    burnRate: bigint;
    totalBurn: bigint;
    health: {
      healthy: number;
      unhealthy: number;
      frozen: number;
      pending: number;
    };
    topups: {
      count: number;
      total: bigint;
    };
  };
}

function useProjectsWithMetricsQuery() {
  const projects = useProjectsQuery();
  const canisters = useCanisterTableQuery();
  const charges = useChargesQuery({ limit: 1000 });
  const principal = useActivePrincipalQuery();

  return useQuery({
    queryKey: ["projects", "with-metrics", principal.data?.toText()],
    queryFn: () => {
      if (!projects.data || !canisters.data || !charges.data) {
        throw new Error("Missing data");
      }

      return projects.data.map((project): ProjectWithMetrics => {
        // Get all canisters for this project
        const projectCanisters = canisters.data.filter(
          (canister: CanisterTableData) => canister.project === project.name
        );

        // Get all charges for canisters in this project
        const projectCharges = charges.data.filter((charge) =>
          projectCanisters.some(
            (canister) => canister.id.toText() === charge.canister.toText()
          )
        );

        // Calculate topups metrics
        const topupsMetrics = {
          count: projectCharges.length,
          total: projectCharges.reduce((acc, charge) => {
            if ("cycles" in charge.amount) {
              return acc + charge.amount.cycles.e12s;
            }
            return acc;
          }, 0n),
        };

        // Calculate metrics
        const metrics = {
          burnRate: projectCanisters.reduce(
            (acc: bigint, canister: CanisterTableData) =>
              acc + (canister.burnPerDay || 0n),
            0n
          ),
          totalBurn: projectCanisters.reduce(
            (acc: bigint, canister: CanisterTableData) =>
              acc + canister.burnTotal,
            0n
          ),
          health: {
            healthy: projectCanisters.filter(
              (c: CanisterTableData) => c.status === "healthy"
            ).length,
            unhealthy: projectCanisters.filter(
              (c: CanisterTableData) => c.status === "low balance"
            ).length,
            frozen: projectCanisters.filter(
              (c: CanisterTableData) => c.status === "frozen"
            ).length,
            pending: projectCanisters.filter(
              (c: CanisterTableData) => c.status === "pending"
            ).length,
          },
          topups: topupsMetrics,
        };

        return {
          id: project.id,
          name: project.name,
          owner: project.owner,
          createdTimestamp: project.createdTimestamp,
          canisters: projectCanisters,
          metrics,
        };
      });
    },
    enabled:
      projects.isFetched &&
      canisters.isFetched &&
      charges.isFetched &&
      principal.isFetched,
  });
}

export type { ProjectWithMetrics };
export { useProjectsWithMetricsQuery };

export {
  useCanisterTagsMutation,
  useCreateProjectMutation,
  useRenameProjectMutation,
  useDeleteProjectMutation,
  useCanisterProjectMutation,
  useTransferProjectMutation,
  useBatchCanisterTagMutation,
  useSingleCanisterProjectMutation,
  useBatchCanisterProjectMutation,
};
