import { Principal } from "@dfinity/principal";
import {
  ExternalLink,
  ChevronDown,
  ChevronDownSquare,
  ChevronUp,
  ChevronUpSquare,
  BatteryCharging,
  List,
} from "lucide-react";
import { ComponentPropsWithoutRef, useEffect, useMemo, useState } from "react";

import BurnChart from "@/components/burn-chart";
import CyclesBalanceChart from "@/components/cycles-balance-chart";
import Blip from "@/components/ui/blip";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import Hash from "@/components/ui/hash";
import { Separator } from "@/components/ui/separator";
import { useCyclesPriceQuery } from "@/hooks/queries/cycleops-service";
import { useCanisterMetadataQuery } from "@/hooks/queries/internet-computer";
import { useCustomerICPBalanceQuery } from "@/hooks/queries/ledger-icp-legacy";
import { AppLink } from "@/hooks/queries/team";
import { useActiveTags } from "@/hooks/use-active-tags";
import { mapOptional, mapTrillions, ruleToString } from "@/lib/ic-utils";
import {
  estimateNextTopup,
  estimateFreezeDate,
  getLastOkMonitor,
  getCanisterHealth,
  mapFreezingThreshold,
  RawCanisterResponse,
} from "@/lib/insights/canister-insights";
import CyclesAnalysis, {
  getLastTopup,
} from "@/lib/insights/timeseries-insights";
import { cn, formatAsDollar, relative, uint8ArrayToHex } from "@/lib/ui-utils";

import ManualTopUpCanister from "./manual-top-up-canister";
import MemoryChart from "./memory-chart";
import { Skeleton } from "./ui/skeleton";
import Tag from "./ui/tag";

interface TableHeadProps extends ComponentPropsWithoutRef<"div"> {
  handlers: {
    toggleAll: () => void;
  };
  open: boolean;
}

function TableHead({ handlers, open, className }: TableHeadProps) {
  return (
    <div
      className={cn(
        "px-4 h-10 flex gap-4 items-center justify-between text-left text-xs text-muted-foreground sticky top-0 bg-background shadow-lg z-50",
        className
      )}
    >
      <div className="basis-44">Canister</div>
      <div className="basis-32">Balance</div>
      <div className="basis-24 hidden @sm:block">Burn Rate</div>
      <div className="basis-24 hidden @2xl:block">Burn Total</div>
      <div className="basis-64 hidden @3xl:block">Topup Rule</div>
      <div className="cursor-pointer" onClick={() => handlers.toggleAll()}>
        {open ? (
          <ChevronUpSquare size="20px" />
        ) : (
          <ChevronDownSquare size="20px" />
        )}
      </div>
    </div>
  );
}

interface NutritionFactsProps extends ComponentPropsWithoutRef<"div"> {
  principal: string;
  status: "healthy" | "low balance" | "frozen" | "pending";
  controllers: string[];
  moduleHash?: string;
  subnet: string;
  lastBalanceCheck: Date | undefined;
  nextBalanceCheck: Date | undefined;
  lastTopUp?: {
    date: Date;
    amount: { trillions: number };
  };
  burn: {
    idlePerDay: { trillions: number };
    idleTotal: { trillions: number };
    perDayAvg: { trillions: number };
    total: { trillions: number };
    period: { days: number };
  };
  nextTopUpEst?: Date;
  freezing: {
    threshold: { seconds: number; days: number; cycles: number };
    timeUntilFreezeEst?: Date;
  };
}

function reduceNutritionFacts(raw: RawCanisterResponse): NutritionFactsProps {
  const [latestStatusTimestamp, latestStatus] = getLastOkMonitor(raw);
  const analysis = CyclesAnalysis.canisterOverviewAlgorithm(
    CyclesAnalysis.cyclesBalancesFromStatusHistory(raw[2]),
    CyclesAnalysis.DataComposition.decomposeSimplePair,
    CyclesAnalysis.DataComposition.recomposeSimplePair,
    (x) => x[0]
  );
  const settings = latestStatus && mapOptional(latestStatus.settings);

  const principal = raw[0].toText();
  const status = getCanisterHealth(raw);
  const balance = latestStatus ? Number(latestStatus.cycles) : NaN;
  const controllers = settings?.controllers.map((x) => x.toText()) ?? [];
  const moduleHash = latestStatus
    ? uint8ArrayToHex(mapOptional(latestStatus.module_hash))
    : undefined;
  const subnet = ""; // TODO: fetch subnet
  const lastBalanceCheck = latestStatusTimestamp;
  const SIX_HOURS = 21600000;
  const nextBalanceCheck = latestStatus
    ? new Date(latestStatusTimestamp.getTime() + SIX_HOURS)
    : undefined;
  const lastTopUp = getLastTopup(analysis);
  const burn = {
    idlePerDay: {
      trillions: latestStatus
        ? Number(latestStatus.idle_cycles_burned_per_day)
        : NaN,
    },
    period: { days: analysis.total.days },
    perDayAvg: {
      trillions: Number(analysis.total.burn.raw * 1e12) / analysis.total.days,
    },
    total: { trillions: Number(analysis.total.burn.raw * 1e12) },
    idleTotal: {
      trillions: raw[2].reduce((a, x) => {
        const i = "ok" in x[1] ? Number(x[1].ok.idle_cycles_burned_per_day) : 0;
        return a + i;
      }, 0),
    },
  };
  const nextTopUpEst = estimateNextTopup({
    averageBurnPerDayTC: burn.perDayAvg.trillions,
    currentBalanceTC: balance,
    topUpThresholdTC: Number(raw[1].topupRule.threshold),
  });
  const threshold = mapFreezingThreshold({
    freezingThreshold: Number(settings?.freezing_threshold),
    idleBurn: burn.idlePerDay.trillions,
  });
  const freezing = {
    threshold,
    timeUntilFreezeEst: estimateFreezeDate({
      averageBurnPerDayTC: burn.perDayAvg.trillions,
      currentBalanceTC: balance,
      freezingThresholdTC: threshold.cycles,
    }),
  };
  return {
    principal,
    status,
    controllers,
    moduleHash,
    subnet,
    lastBalanceCheck,
    nextBalanceCheck,
    lastTopUp,
    burn,
    nextTopUpEst,
    freezing,
  };
}

function NutritionFacts({
  className,
  principal,
  ...props
}: NutritionFactsProps) {
  const metadata = useCanisterMetadataQuery(principal.toString()).data;
  return (
    <div
      className={cn(
        "grid grid-cols-1 @md:grid-cols-2 @3xl:grid-cols-3 gap-3 text-xs text-muted-foreground font-light font-mono",
        className
      )}
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <div className="flex gap-2 items-center">
              <div className="text-foreground">🔋</div>
              <div className="text-foreground font-bold">Canister Status</div>
            </div>
            {props.status}
          </div>
          <Separator className="h-0 border-b-[1px] border-border" />
          <div className="flex flex-col gap-2 pl-4">
            <div className="flex justify-between">
              <div className="text-foreground">Last Balance Check</div>{" "}
              {props.lastBalanceCheck ? relative(props.lastBalanceCheck) : "-"}
            </div>
            <Separator className="h-0 border-b-[1px] border-border" />
            <div className="flex justify-between">
              <div className="text-foreground">Next Balance Check</div>{" "}
              {props.nextBalanceCheck ? relative(props.nextBalanceCheck) : "-"}
            </div>
            <Separator className="h-0 border-b-[1px] border-border" />
            <div className="flex justify-between ">
              <div className="text-foreground">Last Top-Up</div>
              {props.lastTopUp && (
                <>
                  {relative(props.lastTopUp.date)} /{" "}
                  {mapTrillions(props.lastTopUp.amount.trillions, true)}
                </>
              )}
            </div>
            <Separator className="h-0 border-b-[1px] border-border" />
            <div className="flex justify-between">
              <div className="text-foreground">Next Top-Up Est.</div>
              {props.nextTopUpEst ? relative(props.nextTopUpEst) : "-"}
            </div>
          </div>
          <Separator className="h-0 border-b-[2px] border-border" />
        </div>
        <div className="flex flex-col gap-2 py-2">
          <div className="flex gap-2 items-center">
            🔗
            <div className="text-foreground font-bold">
              Canister ID (Principal)
            </div>
          </div>
          {principal}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <div className="flex gap-2 items-center">
              🔥
              <div className="text-foreground font-bold">Cycles Burn</div>
            </div>
            {mapTrillions(props.burn.perDayAvg.trillions, true)} per day
          </div>
          <Separator className="h-0 border-b-[1px] border-border" />
          <div className="flex flex-col gap-2 pl-4">
            <div className="flex justify-end">
              {mapTrillions(props.burn.total.trillions, true)} last{" "}
              {props.burn.period.days} days
            </div>
            <Separator className="h-0 border-b-[1px] border-border" />
            <div className="flex justify-between">
              <div className="text-foreground">Idle Cycles Burn</div>
              {mapTrillions(props.burn.idlePerDay.trillions, true)} per day
            </div>
            <Separator className="h-0 border-b-[1px] border-border" />
            <div className="flex justify-end">
              {mapTrillions(props.burn.idleTotal.trillions, true)} last{" "}
              {props.burn.period.days} days
            </div>
          </div>
          <Separator className="h-0 border-b-[2px] border-border" />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <div className="flex gap-2 items-center">
              ❄️
              <div className="text-foreground font-bold">
                Freezing Threshold
              </div>
            </div>
            {props.freezing.threshold.days} days
          </div>
          <Separator className="h-0 border-b-[1px] border-border" />
          <div className="flex flex-col gap-2 pl-4">
            <div className="flex justify-between">
              <div className="italic">
                {props.freezing.threshold.days} days ×{" "}
                {mapTrillions(props.burn.idlePerDay.trillions, true)} per day =
              </div>
              {mapTrillions(props.freezing.threshold.cycles, true)} Cycles
            </div>
            <Separator className="h-0 border-b-[1px] border-border" />
            <div className="flex justify-between">
              <div className="text-foreground">Time Until Freeze Est.</div>
              {props.freezing.timeUntilFreezeEst
                ? relative(props.freezing.timeUntilFreezeEst)
                : "-"}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2 pb-2">
          <div className="flex gap-2 items-center">
            🌐
            <div className="text-foreground font-bold">Subnet ID</div>
          </div>

          <div className="max-w-full overflow-x-hidden relative">
            {metadata && (
              <a
                href={`https://dashboard.internetcomputer.org/subnet/${metadata.subnet}`}
                target="_blank"
                className="text-foreground whitespace-nowrap plain"
              >
                <ExternalLink size="16" className="inline" /> {metadata.subnet}
              </a>
            )}
            <div className="absolute pointer-events-none inset-0 [box-shadow:_-10px_0_5px_hsl(var(--background))_inset]" />
          </div>
        </div>
        <Separator className="h-0 border-b-[2px] border-border" />
        <div className="flex flex-col gap-2 pb-2">
          <div className="flex gap-2 items-center">
            #️⃣
            <div className="text-foreground font-bold">Module Hash</div>
          </div>
          <div className="max-w-full overflow-x-hidden relative">
            <div className="absolute pointer-events-none inset-0 [box-shadow:_-10px_0_5px_hsl(var(--background))_inset]" />
            {props.moduleHash}
          </div>
        </div>
        <Separator className="h-0 border-b-[2px] border-border" />
        <div className="flex flex-col gap-2 pb-2">
          <div className="flex gap-2 items-center">
            🔑
            <div className="text-foreground font-bold">Controllers</div>
          </div>
          <div className="flex flex-col gap-1">
            {props.controllers.map((x) => (
              <div
                key={`${principal}-controller-${x}`}
                className="max-w-full overflow-x-hidden relative"
              >
                <div className="absolute pointer-events-none inset-0 [box-shadow:_-10px_0_5px_hsl(var(--background))_inset]" />
                {x}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

interface Canister {
  canister: {
    name: string;
    principal: string;
  };
  tags: string[];
  project?: string;
  rule: {
    amount: { byAmount: { e8s: number } } | { toBalance: { e8s: number } };
    threshold: { e8s: number };
  };
  status: "healthy" | "low balance" | "frozen" | "pending";
  balance: { trillions: number; timestamp: number };
  burn: {
    idle: { trillions: number };
    perDay: { trillions: number };
    currentPeriod: {
      amount: { trillions: number };
      label: string;
    };
  };
  freezingThreshold: number;
  memoryThreshold?: number;
}

function reduceCanister(raw: RawCanisterResponse): Canister {
  const [latestStatusTimestamp, latestStatus] = getLastOkMonitor(raw);

  const analysis = CyclesAnalysis.canisterOverviewAlgorithm(
    CyclesAnalysis.cyclesBalancesFromStatusHistory(raw[2]),
    CyclesAnalysis.DataComposition.decomposeSimplePair,
    CyclesAnalysis.DataComposition.recomposeSimplePair,
    (x) => x[0]
  );

  const canister = {
    name: raw[1].name,
    principal: raw[1].id.toText(),
  };

  const tags = mapOptional(raw[3])?.keywordTags ?? [];
  const project = mapOptional(raw[3])?.projectName?.[0];

  const rule = {
    amount:
      "by_amount" in raw[1].topupRule.method
        ? { byAmount: { e8s: Number(raw[1].topupRule.method.by_amount) } }
        : { toBalance: { e8s: Number(raw[1].topupRule.method.to_balance) } },
    threshold: { e8s: Number(raw[1].topupRule.threshold) },
  };

  const status = getCanisterHealth(raw);

  if (!latestStatus)
    return {
      canister,
      tags,
      project,
      rule,
      status,
      balance: { trillions: 0, timestamp: 0 },
      burn: {
        idle: { trillions: 0 },
        perDay: { trillions: 0 },
        currentPeriod: {
          amount: { trillions: 0 },
          label: "",
        },
      },
      freezingThreshold: 0,
    };

  const balance = {
    trillions: Number(latestStatus.cycles),
    timestamp: Number(latestStatusTimestamp),
  };

  const burn = {
    idle: { trillions: Number(latestStatus.idle_cycles_burned_per_day) },
    perDay: {
      trillions: Number(analysis.total.burn.raw * 1e12) / analysis.total.days,
    },
    currentPeriod: {
      amount: { trillions: Number(analysis.total.burn.raw * 1e12) },
      label: `${analysis.total.days} Days`,
    },
  };

  const freezingThreshold = Number(
    latestStatus.settings[0]!.freezing_threshold
  );

  const memoryThreshold = mapOptional(raw[1].memoryThreshold)
    ? Number(mapOptional(raw[1].memoryThreshold))
    : undefined;

  return {
    canister,
    tags,
    project,
    rule,
    status,
    balance,
    burn,
    freezingThreshold,
    memoryThreshold,
  };
}

function reduceMemorySeries(raw: RawCanisterResponse) {
  return raw[2]
    .map((x) => {
      if ("ok" in x[1]) {
        const { ok } = x[1];
        return {
          date: new Date(Number(x[0]) / 1e6),
          memory: Number(ok.memory_size),
        };
      }
      return undefined;
    })
    .filter((x) => x !== undefined) as { date: Date; memory: number }[];
}

function SkeletonCanisterGrid() {
  return <Skeleton className="w-full h-[400px]" />;
}

export interface Props extends ComponentPropsWithoutRef<"div"> {
  response: RawCanisterResponse[];
  loading?: boolean;
}

export default function CanisterGrid({
  className,
  response,
  loading,
  ...props
}: Props) {
  const [open, setOpen] = useState(response.map((_, i) => i === 0));
  const { activeTags, setActiveTag, activeProject, toggleActiveProject } =
    useActiveTags();

  useEffect(() => {
    setOpen(response.map((_, i) => open[i] ?? i === 0));
  }, [response]);
  const toggle = (i: number) => {
    const updated = [...open];
    updated[i] = !updated[i];
    setOpen(updated);
  };
  const toggleAll = () => {
    const updated = open.map(() => !open[0]);
    setOpen(updated);
  };
  const analysis = useMemo(
    () =>
      response.map((raw) =>
        CyclesAnalysis.canisterOverviewAlgorithm(
          CyclesAnalysis.cyclesBalancesFromStatusHistory(raw[2]),
          CyclesAnalysis.DataComposition.decomposeSimplePair,
          CyclesAnalysis.DataComposition.recomposeSimplePair,
          (x) => x[0]
        )
      ),
    [response]
  );
  const ICPBalance = useCustomerICPBalanceQuery().data;
  const cyclesPrice = useCyclesPriceQuery();
  return (
    <div
      className={cn(
        "flex flex-col gap-4 whitespace-nowrap @container pt-4",
        className
      )}
      {...props}
    >
      <TableHead handlers={{ toggleAll }} open={open[0]!!} />
      {loading === false && response.length === 0 && (
        <div className="w-full flex flex-col items-center justify-center padding-4 py-24 min-h-[320px] border rounded-lg gap-8">
          <div className="text-7xl">😶‍🌫️</div>
          You don't have any canisters yet!
          <AppLink to="/canisters/new" className="plain">
            <Button variant="default" size="lg" className="text-lg py-8 px-12">
              Add a Canister
            </Button>
          </AppLink>
          <div className="text-sm">
            Trying to join a team?{" "}
            <a
              href="https://docs.cycleops.dev/docs/basics/teams-management"
              target="_blank"
            >
              How to join a team.
            </a>
          </div>
        </div>
      )}
      {loading && <SkeletonCanisterGrid />}
      {response
        .map(reduceCanister)
        .map(
          (
            {
              canister,
              tags,
              project,
              rule,
              status,
              balance,
              burn,
              freezingThreshold,
              memoryThreshold,
            },
            i
          ) => (
            <Card
              key={`canister-card-${canister.principal}`}
              className="overflow-hidden"
            >
              <Collapsible open={open[i]} onOpenChange={() => toggle(i)}>
                <CollapsibleTrigger className="px-4 py-3 flex gap-4 items-center justify-between w-full text-left bg-neutral-50 dark:bg-neutral-900">
                  <div className="flex gap-4 items-center basis-44">
                    <Blip
                      status={status}
                      className="flex-shrink-0 flex-grow-0"
                    />
                    <div className="flex flex-col gap-1 items-start">
                      <div className="font-semibold" data-pii>
                        {canister.name}
                      </div>
                      <Hash className="text-xs px-1 py-0" capture>
                        {canister.principal}
                      </Hash>
                    </div>
                  </div>
                  <div className="flex flex-col text-sm basis-32">
                    {mapTrillions(balance.trillions, true)} Cycles
                    <div className="text-xs text-muted-foreground">
                      {relative(new Date(balance.timestamp))}
                    </div>
                  </div>
                  <div className="hidden @sm:flex flex-col text-sm basis-24">
                    {mapTrillions(burn.perDay.trillions, true)} 🔥
                    <div className="text-xs text-muted-foreground">Per Day</div>
                  </div>
                  <div className="flex-col text-sm basis-24 hidden @2xl:flex">
                    {mapTrillions(burn.currentPeriod.amount.trillions, true)} 🔥
                    <div className="text-xs text-muted-foreground">
                      {burn.currentPeriod.label}
                    </div>
                  </div>
                  <div className="text-sm basis-64 hidden @3xl:block">
                    ⚡ {ruleToString(rule)}
                  </div>
                  {open[i] ? <ChevronUp /> : <ChevronDown />}
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <Separator />
                  <div className="p-4 pt-2 flex flex-col gap-3">
                    <header className="flex gap-4 items-center justify-between">
                      <div className="flex gap-1">
                        {project ? (
                          <Tag
                            key={`tag-${project}`}
                            variant="project"
                            onClick={() => toggleActiveProject(project)}
                            className="mr-2"
                            status={
                              activeProject === project ? "active" : "inactive"
                            }
                            data-pii
                          >
                            {project}
                          </Tag>
                        ) : null}
                        {tags.sort().map((tag) => (
                          <Tag
                            key={`tag-${tag}`}
                            onClick={() => setActiveTag(tag)}
                            status={
                              activeTags.includes(tag) ? "active" : "inactive"
                            }
                            data-pii
                          >
                            {tag}
                          </Tag>
                        ))}
                      </div>
                      <div className="flex gap-2">
                        <AppLink
                          className="plain"
                          to={`canisters/${canister.principal}`}
                        >
                          <Button
                            variant="default"
                            size="sm"
                            className="flex gap-2 text-xs"
                          >
                            <List size="16px" />
                            Canister Settings
                          </Button>
                        </AppLink>
                        <ManualTopUpCanister
                          canister={{
                            name: canister.name,
                            id: Principal.fromText(canister.principal),
                            status,
                            cycles: balance,
                          }}
                          ICPBalance={
                            ICPBalance && { e8s: Number(ICPBalance.e8s) }
                          }
                        >
                          <Button
                            variant="default"
                            size="sm"
                            className="flex gap-2 text-xs"
                          >
                            <BatteryCharging size="16px" />
                            Top-Up Now
                          </Button>
                        </ManualTopUpCanister>
                      </div>
                    </header>
                    <div className="grid grid-cols-1 @lg:grid-cols-3 gap-3">
                      <div className="max-w-full col-span-1 aspect-video ring-[1px] ring-border rounded-sm overflow-hidden relative">
                        <div className="p-3 pb-0 font-medium absolute top-0 left-0">
                          Cycles Balance
                        </div>
                        <CyclesBalanceChart
                          className=""
                          topups={analysis[i]!.topups.filter(
                            ([, v]) => v !== null
                          ).map(([date]) => date)}
                          data={analysis[i]!.balance.filter(
                            (x) => x[1] !== null
                          ).map((x) => ({
                            date: x[0],
                            balance: (x[1] as number) * 1e12,
                          }))}
                          thresholds={{
                            topup: rule.threshold.e8s,
                            freezing: mapFreezingThreshold({
                              freezingThreshold,
                              idleBurn: burn.idle.trillions,
                            }).cycles,
                          }}
                        />
                      </div>
                      <div className="col-span-1 aspect-video ring-[1px] ring-border rounded-sm overflow-hidden relative">
                        <div className="p-3 pb-0 flex justify-between items-baseline absolute top-0 left-0 right-0">
                          <div className="font-medium">Burn Rate</div>
                          <div className="text-xs text-muted-foreground">
                            {mapTrillions(
                              burn.currentPeriod.amount.trillions,
                              true
                            )}{" "}
                            /{" "}
                            {formatAsDollar(
                              cyclesPrice.data?.usdPerTrillionCycles &&
                                (burn.currentPeriod.amount.trillions / 1e12) *
                                  cyclesPrice.data.usdPerTrillionCycles
                            )}{" "}
                            Past {burn.currentPeriod.label}
                          </div>
                        </div>
                        <BurnChart
                          className=""
                          anomalies={analysis[i]!.anomalies.filter(
                            ([, v]) => v !== null
                          ).map(([date]) => date)}
                          data={analysis[i]!.burn.filter(
                            (x) => x[1] !== null
                          ).map((x) => ({
                            date: x[0],
                            burn: (x[1] as number) * 1e12,
                            usd:
                              cyclesPrice.data?.usdPerTrillionCycles &&
                              (x[1] as number) *
                                cyclesPrice.data.usdPerTrillionCycles,
                          }))}
                        />
                      </div>
                      <div className="col-span-1 aspect-video ring-[1px] ring-border rounded-sm overflow-hidden relative">
                        <div className="p-3 pb-0 font-medium absolute top-0 left-0">
                          Memory
                        </div>
                        <MemoryChart
                          className=""
                          data={reduceMemorySeries(response[i]!)}
                          threshold={memoryThreshold}
                        />
                      </div>
                    </div>
                    <Separator />
                    <NutritionFacts {...reduceNutritionFacts(response[i]!)} />
                  </div>
                </CollapsibleContent>
              </Collapsible>
              <Collapsible open={!open[i]}>
                <CollapsibleContent className="px-4 pb-1 -mt-1 flex gap-2 items-center w-full text-left bg-neutral-50 dark:bg-neutral-900">
                  {project ? (
                    <Tag
                      key={`tag-${project}`}
                      variant="project-link"
                      onClick={() => toggleActiveProject(project)}
                      className="mr-2"
                      status={activeProject === project ? "active" : "inactive"}
                      data-pii
                    >
                      {project}
                    </Tag>
                  ) : null}
                  {tags.sort().map((tag) => (
                    <Tag
                      key={`tag-${tag}`}
                      variant="tag-link"
                      onClick={() => setActiveTag(tag)}
                      className="mr-0"
                      status={activeTags.includes(tag) ? "active" : "inactive"}
                      data-pii
                    >
                      {tag}
                    </Tag>
                  ))}
                </CollapsibleContent>
              </Collapsible>
            </Card>
          )
        )}
    </div>
  );
}
