import { Principal } from "@dfinity/principal";
import { Copy, Info, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  monitoringMechanism,
  NullCell,
  Status,
} from "@/components/canister-list/table/cells";
import Code from "@/components/code";
import { BlackholeVerificationCard } from "@/components/onboarding";
import { NotFoundError } from "@/components/pages/new/error-404";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
  useCanisterNameMutation,
  useCanisterTableDetailQuery,
  useVerifyBlackholeMutation,
  useVerifyBlackholeQuery,
  useVerifyBlackholeVersionedMutation,
  useVerifyBlackholeVersionedQuery,
} from "@/hooks/queries/canisters";
import {
  useBalanceChecker,
  useBalanceCheckerV1,
  useBalanceCheckerV2,
} from "@/hooks/queries/cycleops-service";
import { AppLink } from "@/hooks/queries/team";
import { ic } from "@/lib/actors";
import { copy } from "@/lib/ui-utils";

function CanisterID() {
  const { canisterId } = useParams();
  return (
    <Card>
      <CardHeader>
        <CardTitle>Canister ID</CardTitle>
        <CardDescription>
          Your canister's ID on internet computer mainnet.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div
          className="relative max-w-[400px]"
          onClick={() => copy(canisterId)}
        >
          <Input
            value={canisterId}
            readOnly
            className="cursor-pointer text-muted-foreground"
          />
          <Button
            size="icon"
            variant="outline"
            className="absolute right-1 top-1 bottom-1 w-8 h-8 rounded-sm"
          >
            <Copy className="w-4 h-4" />
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

function useCanisterOr404() {
  const { canisterId } = useParams();
  if (!canisterId) throw new NotFoundError("Canister ID not found");
  const canister = useCanisterTableDetailQuery(Principal.fromText(canisterId));
  if (canister.isError) throw new NotFoundError("Canister not found");
  return canister;
}

function CanisterNameSettings() {
  const canister = useCanisterOr404();
  const [name, setName] = useState(canister.data?.name || "");
  const canisterName = useCanisterNameMutation();

  function handleSaveName(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (!canister.data) return;
    canisterName.mutate({
      canisterId: canister.data?.id,
      name,
    });
  }

  useEffect(() => {
    if (!canister.data) return;
    setName(canister.data?.name || "");
  }, [canister.data?.name]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Canister Name</CardTitle>
        <CardDescription>
          A nice label describing your canister.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="max-w-[400px] mb-2">
          <Input
            placeholder="My Canister"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <Button onClick={handleSaveName} loading={canisterName.isPending}>
          Save
        </Button>
      </CardContent>
    </Card>
  );
}

function VerifyDialog({
  canisterId,
  blackholeVersion,
  handlers,
}: {
  canisterId: string;
  blackholeVersion: bigint;
  handlers: {
    success(): void;
  };
}) {
  const statusCheckerV1 = useBalanceCheckerV1();
  const statusCheckerV2 = useBalanceCheckerV2();
  const blackhole =
    blackholeVersion === 1n ? statusCheckerV1.data : statusCheckerV2.data;
  const dfxCommand = `dfx canister${
    ic.isLocal ? "" : " --network ic"
  } update-settings ${canisterId} \\\n--add-controller ${
    blackhole || "BALANCE_CHECKER_CANISTER_ID"
  }`;
  const verify = useVerifyBlackholeVersionedMutation(blackholeVersion);

  function handleVerify(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    verify.mutate(canisterId);
  }

  useEffect(() => {
    if (verify.isSuccess) {
      handlers.success();
    }
  }, [verify.isSuccess]);

  return (
    <div className="flex flex-col gap-4 text-sm">
      <DialogTitle className="text-lg font-medium">
        Grant control to the blackhole
      </DialogTitle>
      <DialogDescription className="flex flex-col gap-4 text-foreground">
        <p>
          The CycleOps blackhole is ready to start monitoring your canister!
          Just add it as a controller of your canister.{" "}
          <a
            href="https://github.com/CycleOperators/BalanceCheckerVerification"
            target="_blank"
          >
            Learn more
          </a>
        </p>
        <p>
          Run this from the project directory in your terminal, then press
          verify.
        </p>
        <Code copyable={dfxCommand}>{dfxCommand}</Code>
      </DialogDescription>
      <Button onClick={handleVerify} loading={verify.isPending}>
        Verify
      </Button>
    </div>
  );
}

function MonitoringConnection({ canisterId }: { canisterId: string }) {
  const canister = useCanisterTableDetailQuery(Principal.fromText(canisterId));
  const blackholeVersion =
    canister.data && "blackhole" in canister.data.monitoringMechanism
      ? canister.data?.monitoringMechanism.blackhole
      : undefined;
  const isVerified = useVerifyBlackholeVersionedQuery({
    canisterId,
    blackholeVersion,
  });

  const [verifyDialogOpen, setVerifyDialogOpen] = useState(false);

  if (isVerified.isLoading) return <Loader2 className="w-3 h-3 animate-spin" />;
  if (!canister.data) return null;
  if (!("blackhole" in canister.data.monitoringMechanism)) return <NullCell />;

  return (
    <div className="flex items-baseline gap-4">
      <Status value={isVerified.data ? "healthy" : "low balance"} />
      {isVerified.data === false && !!blackholeVersion && (
        <Dialog open={verifyDialogOpen} onOpenChange={setVerifyDialogOpen}>
          <DialogTrigger asChild>
            <div className="underline cursor-pointer">Verify</div>
          </DialogTrigger>
          <DialogContent className="max-w-[600px]">
            <VerifyDialog
              canisterId={canisterId!}
              blackholeVersion={blackholeVersion}
              handlers={{
                success() {
                  setVerifyDialogOpen(false);
                },
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

function MonitoringSettings() {
  const canister = useCanisterOr404();

  return (
    <Card>
      <CardHeader>
        <CardTitle>Monitoring</CardTitle>
        <CardDescription></CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div className="grid grid-cols-[auto_1fr] gap-x-8 gap-y-1 items-baseline text-sm">
            <div className="">Monitoring Mechanism</div>
            <div className="">
              {monitoringMechanism(canister.data?.monitoringMechanism)}
            </div>
            <div className="">Monitoring Connection</div>
            <div className="">
              {canister.data && (
                <MonitoringConnection canisterId={canister.data.id.toText()} />
              )}
            </div>
          </div>
        </div>
        <UpgradeBlackhole />
      </CardContent>
      <CardFooter>
        <div className="flex justify-between">
          <small>
            Learn more about{" "}
            <a
              href="https://docs.cycleops.dev/docs/basics/integrating-canisters"
              target="_blank"
            >
              integrating canisters with CycleOps
            </a>
          </small>
        </div>
      </CardFooter>
    </Card>
  );
}

function UpgradeBlackhole() {
  const canister = useCanisterOr404();

  if (!canister.data) return null;
  if (canister.isLoading) return null;
  if ("nns" in canister.data.monitoringMechanism) return null;
  if ("sns" in canister.data.monitoringMechanism) return null;
  if ("publicStatus" in canister.data.monitoringMechanism) return null;
  if (canister.data.monitoringMechanism.blackhole === 2n) return null;

  return (
    <AppLink
      to="/blackhole-upgrade"
      className="bg-blue-800/10 border-blue-800/25 rounded-sm border p-2 flex flex-col gap-2 hover:bg-blue-800/20 hover:border-blue-800/35 transition-colors cursor-pointer"
    >
      <div className="text-sm flex items-center gap-2">
        <Info className="w-4 h-4" /> Upgrade blackhole now
      </div>
      <div className="text-xs max-w-[20rem] opacity-75">
        This canister is monitored with an old blackhole version. Upgrade in a
        few minutes for improved monitoring.
      </div>
      <Button
        className="w-auto self-start text-xs px-2 py-1 h-6 rounded-sm"
        size="sm"
      >
        Upgrade now
      </Button>
    </AppLink>
  );
}

function CanisterGeneralSettings() {
  return (
    <div className="flex flex-col gap-2">
      <CanisterID />
      <CanisterNameSettings />
      <MonitoringSettings />
    </div>
  );
}

export { CanisterGeneralSettings };
