import { cn } from "@/lib/ui-utils";

interface CanisterCountProps {
  count: number;
  className?: string;
}

export function CanisterCount({ count, className }: CanisterCountProps) {
  return (
    <div className="flex justify-start px-3 py-3 text-xs">
      <div className={cn("px-3 py-2 bg-muted/50 rounded-sm", className)}>
        {count} Canister{count !== 1 ? "s" : ""}
      </div>
    </div>
  );
}
