import { ExternalLink, X } from "lucide-react";
import { useMemo } from "react";

import { Button } from "@/components/ui/button";
import { AppLink } from "@/hooks/queries/team";
import { useNewUIFlag } from "@/hooks/use-flags";
import { useNotificationStore } from "@/state/stores/notifications";

export default function ExperimentalUINotification() {
  const { isDismissed, dismissNotification } = useNotificationStore();
  const flag = useNewUIFlag();

  if (isDismissed("experimental-ui")) return null;
  if (flag === false) return null;

  function handleDismiss() {
    dismissNotification("experimental-ui");
  }

  return (
    <div className="bg-blue-500/5 border-blue-500/20 rounded-md border p-4 flex flex-col gap-4 relative">
      <Button
        variant="ghost"
        size="icon"
        className="absolute top-2 right-2 h-6 w-6"
        onClick={handleDismiss}
      >
        <X className="h-4 w-4" />
      </Button>
      <div className="flex flex-col gap-2">
        <p className="text-sm">CycleOps V2 🎉</p>
        <p className="text-xs text-muted-foreground">
          We've redesigned the app to be more intuitive and powerful.
        </p>
        <div className="flex gap-2">
          <a
            href="https://docs.cycleops.dev/changelog/canister-fleets"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="outline" size="sm" className="text-xs h-6 px-2">
              Learn More
            </Button>
          </a>
          <AppLink to="/settings/experiments">
            <Button variant="outline" size="sm" className="text-xs h-6 px-2">
              Switch Back
            </Button>
          </AppLink>
        </div>
      </div>
    </div>
  );
}
