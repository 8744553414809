import { type ClassValue, clsx } from "clsx";
import { toast } from "sonner";
import { twMerge } from "tailwind-merge";

// Util function to merge Tailwind classes with clsx
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Copy some text to the user's clipboard.
export function copy(string?: string) {
  if (!string) return;
  toast.info("Copied to clipboard", { id: `copy-${string}` });
  navigator.clipboard.writeText(string);
}

const formatter = new Intl.RelativeTimeFormat("en", { style: "narrow" });
const DIVISIONS: {
  amount: number;
  name: Intl.RelativeTimeFormatUnit;
}[] = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
];

export function relative(date: Date): string {
  let duration = (date.getTime() - new Date().getTime()) / 1000;
  if (Number.isNaN(duration)) return "unknown";

  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (!division) throw new Error(`Array out of range: ${i}`);
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
  throw new Error();
}

export function daysIn(from: Date, to: Date) {
  return Math.round((to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24));
}

export function formatAsDollar(amount?: number): string {
  if (amount === undefined) return "$-";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
}

export function formatDate(date: Date): string {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[date.getMonth()];
  const day = date.getDate();
  let hours = date.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours %= 12;
  hours = hours ?? 12; // the hour '0' should be '12'

  return `${month} ${day}, ${hours}${ampm}`;
}

export function uint8ArrayToHex(
  array?: Uint8Array | number[]
): string | undefined {
  if (array === undefined) return undefined;
  return Array.from(array)
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}

export function mapBytesSize(bytes: number) {
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0B";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(Math.round((bytes / 1024 ** i) * 100) / 100).toFixed(0)}${
    sizes[i]
  }`;
}

export function clamp(value: number, min: number, max: number) {
  return Math.max(min, Math.min(max, value));
}

export function searchObject(obj: any, searchString: string): boolean {
  // Check if the current object is null or undefined
  if (obj === null || obj === undefined) {
    return false;
  }

  // Check if the current object itself is the search string
  if (obj.toString().toLowerCase() === searchString.toLowerCase()) {
    return true;
  }

  if (obj._isPrincipal) {
    return obj.toText().includes(searchString);
  }

  // If the object is an array or object, iterate through its properties
  if (typeof obj === "object") {
    for (const key in obj) {
      if (key in obj) {
        const value = obj[key];

        // Recursively search in the current property
        const found = searchObject(value, searchString);
        if (found) {
          return true; // Return true if the string is found
        }
      }
    }
  }

  if (typeof obj === "string") {
    return obj.toLowerCase().includes(searchString.toLowerCase());
  }

  // Return false if the string is not found in the current object
  return false;
}

export function slug(str: string): string {
  return str
    .toLowerCase() // Convert to lowercase
    .trim() // Trim whitespace from both ends
    .replace(/[^\w\s-]/g, "") // Remove all non-word characters (except spaces and hyphens)
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
}

export function formatFloat(value: number): string {
  // Convert number to a string and split into whole and fractional parts
  const [whole, fraction = ""] = value
    .toLocaleString("en", { maximumFractionDigits: 20 })
    .split(".") as [string, string];

  // If there's no fractional part, return the whole number as is
  if (!fraction) {
    return whole;
  }

  // Proceed through the fractional part, cut off once we have enough decimal places

  // How far to search for the first non-zero digit
  const firstDigitSearchDepth =
    whole === "0" ? fraction.length : Math.min(fraction.length, 3);

  // How far after the first digit to search for a digit < 5
  const lastDigitSearchDepth = 3;

  // Maximum fraction digits to show
  const maxFractionDigits = 9;

  let firstSignificantDigitIndex = 0;
  for (let i = 0; i < firstDigitSearchDepth; i++) {
    if (fraction[i] !== "0") {
      firstSignificantDigitIndex = i;
      break;
    }
  }

  // After finding the first non-zero digit, we will cut off as soon as we find a digit < 5
  let lastSignificantDigitIndex = 0;
  for (
    let i = firstSignificantDigitIndex + 1;
    i < firstSignificantDigitIndex + lastDigitSearchDepth;
    i++
  ) {
    if (Number(fraction[i]!) < 5) {
      lastSignificantDigitIndex = i;
      break;
    }
  }

  // If we didn't find a digit < 5, we will cut off at the last digit
  if (lastSignificantDigitIndex === 0) {
    lastSignificantDigitIndex = fraction.length - 1;
  }

  const selectedFraction = fraction.slice(
    0,
    Math.min(lastSignificantDigitIndex + 1, maxFractionDigits)
  );

  // If selectedFraction is only zeros, return the whole number
  if (selectedFraction === "0".repeat(selectedFraction.length)) {
    return whole;
  }

  // Remove trailing zeros
  const lastNonZeroIndex = [...selectedFraction].findIndex((digit) => {
    return digit !== "0";
  });
  const result = `${whole}.${selectedFraction.slice(
    0,
    lastNonZeroIndex === -1 ? selectedFraction.length : lastNonZeroIndex + 2
  )}`;

  return result;
}
