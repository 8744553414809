import { useCyclesPriceQuery } from "@/hooks/queries/cycleops-service";
import { cn } from "@/lib/ui-utils";

function formatCycles(value: number, includeUnit: boolean = false): string {
  // Thresholds in TC
  const BILLION_THRESHOLD = 0.001; // 1B cycles
  const TC_THRESHOLD = 0.1; // 100B cycles

  if (value < BILLION_THRESHOLD) {
    // Show in millions
    const millions = value * 1000;
    return `${millions.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })}${includeUnit ? "M Cycles" : ""}`;
  }

  if (value < TC_THRESHOLD) {
    // Show in billions
    const billions = value * 1000;
    return `${billions.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}${includeUnit ? "B Cycles" : ""}`;
  }

  // Show in TC
  return `${value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}${includeUnit ? " TC" : ""}`;
}

function TC({
  value,
  className,
  figs = 2,
  includeUnit = false,
}: {
  value?: bigint | number;
  className?: string;
  figs?: number;
  includeUnit?: boolean;
}) {
  const v = typeof value === "bigint" ? Number(value) / 1e12 : value;
  if (!v)
    return (
      <span className={cn("", className)}>0{includeUnit ? " TC" : ""}</span>
    );

  const min = 1 / 10 ** figs;
  // For extremely small values
  if (v < min) {
    return (
      <span className={cn("", className)}>
        {`<.${min.toString().split(".")[1]}`}
        {includeUnit ? " TC" : ""}
      </span>
    );
  }

  if (v < 0.001) {
    // Less than 1B cycles
    return (
      <span className={cn("", className)}>{formatCycles(v, includeUnit)}</span>
    );
  }

  if (v < 0.1) {
    // Less than 100B cycles
    return (
      <span className={cn("", className)}>{formatCycles(v, includeUnit)}</span>
    );
  }

  // For values >= 0.1 TC, use the original formatting
  const string = v.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: figs,
  });

  return (
    <span className={cn("", className)}>
      {string}
      {includeUnit ? " TC" : ""}
    </span>
  );
}

function TCtoUSD({
  value,
  className,
}: {
  value?: bigint | number;
  className?: string;
}) {
  const cyclesPrice = useCyclesPriceQuery();
  if (!cyclesPrice.data) return null;
  if (!value) return null;
  const { usdPerTrillionCycles } = cyclesPrice.data;
  const v = typeof value === "bigint" ? Number(value) / 1e12 : value;
  return (
    <span className={cn("", className)}>
      ~$
      {(v * usdPerTrillionCycles).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </span>
  );
}

export { TC, TCtoUSD };
