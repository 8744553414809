import { AppFooter } from "@/components/layout/footer";
import { AppSidebar } from "@/components/layout/sidebar";
import { FocusSidebar } from "@/components/layout/sidebar-focus";
import { SidebarInset } from "@/components/ui/sidebar";

interface BaseLayoutProps {
  children?: React.ReactNode;
  variant?: "default" | "focus";
}

export function BaseLayout({ children, variant = "default" }: BaseLayoutProps) {
  const Sidebar = variant === "focus" ? FocusSidebar : AppSidebar;

  return (
    <>
      <Sidebar />
      <SidebarInset className="bg-sidebar">
        {children}
        <AppFooter />
      </SidebarInset>
    </>
  );
}
