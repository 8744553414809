import { Tokens } from "@dfinity/ledger-icp";
import { Principal } from "@dfinity/principal";
import {
  ColumnDef,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { Copy } from "lucide-react";
import React, { ComponentPropsWithoutRef } from "react";

import { App, AppContents, AppFooter, AppHeader } from "@/components/app";
import DepositDialog from "@/components/deposit-dialog";
import Spinner from "@/components/spinner";
import WithdrawCoordinator from "@/coordination/withdraw";
import { useCustomerPaymentConfQuery } from "@/hooks/queries/customer";
import { useICPQuote } from "@/hooks/queries/cycleops-service";
import {
  useCyclesLedgerAllowanceQuery,
  useCyclesLedgerBalanceQuery,
} from "@/hooks/queries/ledger-cycles";
import {
  useCustomerICPBalanceQuery,
  useCycleOpsAccountTextQuery,
} from "@/hooks/queries/ledger-icp-legacy";
import {
  useExportTransactionsMutation,
  useExportChargesMutation,
  useTransactionsQuery,
  useChargesQuery,
} from "@/hooks/queries/transactions";
import { mapTrillions, readableICP } from "@/lib/ic-utils";
import { cn } from "@/lib/ui-utils";

import { DataTableColumnHeader } from "../data-table/column-header";
import { DataTablePagination } from "../data-table/pagination";
import PageHeader from "../page-header";
import { WalletProvider, Wizard } from "../payment-method-wizard";
import PrincipalAbbr from "../principal-abbr";
import { SetupWalletFlow } from "../setup-wallet-flow";
import { SidebarNav } from "../sidebar-nav";
import TableSkeleton from "../table-skeleton";
import { TokenAmount, TokenAmountInUSD } from "../token-amount";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Dialog, DialogTrigger, DialogContent } from "../ui/dialog";
import Hash from "../ui/hash";
import { Separator } from "../ui/separator";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { UpdateApprovalFlow } from "../update-approval-flow";

export interface BillingProps extends ComponentPropsWithoutRef<"div"> {
  title: string;
  headChildren?: React.ReactNode;
}

export function BillingPage({
  children,
  headChildren,
  className,
  ...props
}: BillingProps) {
  const sidebarNavItems = [
    {
      title: "Payment Method",
      href: `/app/billing/`,
    },
    {
      title: "History",
      href: `/app/billing/history`,
    },
  ];

  return (
    <App {...props}>
      <AppHeader {...props} />
      <AppContents {...props}>
        <PageHeader title={props.title} children={headChildren} />
        <main className="container md:pb-16 h-full">
          <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-12 lg:space-y-0 h-full">
            <aside className="md:-mx-4 lg:w-1/5 py-6 md:pl-4">
              <SidebarNav items={sidebarNavItems} />
            </aside>
            <div className={cn("flex-1 pt-2 md:pt-6", className)}>
              {children}
            </div>
          </div>
        </main>
      </AppContents>
      <AppFooter {...props} />
    </App>
  );
}

export interface Transaction {
  kind: "deposit" | "withdrawal";
  timestamp: Date;
  amount: Tokens;
  from: string;
  to: string;
  txHash: string;
}

export interface Charge {
  timestamp: Date;
  amount: { icp: Tokens } | { cycles: { e12s: bigint } };
  canister: Principal;
  cycles: number;
}

interface Props extends Omit<BillingProps, "title"> {}

export default function Billing(props: Props) {
  const charges = useChargesQuery({ limit: 1000 });
  const transactions = useTransactionsQuery({ perPage: 1000 });
  const exportTransactions = useExportTransactionsMutation();
  const exportCharges = useExportChargesMutation();

  return (
    <BillingPage {...props} title="Billing">
      <div className="flex flex-col gap-5">
        <Card>
          <CardHeader className="border-b">
            <div className="flex justify-between items-center">
              <div className="flex flex-col space-y-1.5">
                <CardTitle>Billing History</CardTitle>
                <CardDescription>
                  Records of all charges CycleOps has made to your account.
                </CardDescription>
              </div>
              <Button
                variant="outline"
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  if (exportCharges.isPending) return;
                  exportCharges.mutate();
                }}
              >
                {exportCharges.isPending ? (
                  <Spinner size="tiny" />
                ) : (
                  "Export CSV"
                )}
              </Button>
            </div>
          </CardHeader>

          <TransactionsTable
            columns={chargesColumns}
            data={charges.data}
            isLoading={!charges.isFetched}
          />
        </Card>

        <Card>
          <CardHeader className="border-b">
            <div className="flex justify-between items-center">
              <div className="flex flex-col space-y-1.5">
                <CardTitle>ICP Account History</CardTitle>
                <CardDescription>
                  Records of all transactions on your ICP top-up account.
                </CardDescription>
              </div>
              <Button
                variant="outline"
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  if (exportTransactions.isPending) return;
                  exportTransactions.mutate();
                }}
              >
                {exportTransactions.isPending ? (
                  <Spinner size="tiny" />
                ) : (
                  "Export CSV"
                )}
              </Button>
            </div>
          </CardHeader>
          <TransactionsTable
            columns={transactionsColumns}
            data={transactions.data}
            isLoading={!transactions.isFetched}
          />
        </Card>
      </div>
    </BillingPage>
  );
}

const transactionsColumns: ColumnDef<Transaction>[] = [
  {
    id: "Type",
    header: DataTableColumnHeader,
    accessorKey: "kind",
  },
  {
    id: "Timestamp",
    header: DataTableColumnHeader,
    accessorKey: "timestamp",
    cell: ({ cell }) => format(cell.getValue<Date>(), "yyyy/MM/dd hh:mmaaa"),
  },
  {
    id: "Amount",
    header: DataTableColumnHeader,
    accessorFn: (row) => row.amount.e8s,
    cell: ({ cell }) => readableICP({ e8s: Number(cell.getValue<bigint>()) }),
  },
  {
    id: "From",
    header: DataTableColumnHeader,
    accessorKey: "from",
    cell: ({ cell }) => (
      <PrincipalAbbr>{cell.getValue<string>()}</PrincipalAbbr>
    ),
  },
  {
    id: "To",
    header: DataTableColumnHeader,
    accessorKey: "to",
    cell: ({ cell }) => (
      <PrincipalAbbr>{cell.getValue<string>()}</PrincipalAbbr>
    ),
  },
  {
    id: "TxHash",
    header: DataTableColumnHeader,
    accessorKey: "txHash",
    cell: ({ cell }) => (
      <PrincipalAbbr>{cell.getValue<string>()}</PrincipalAbbr>
    ),
  },
];

const chargesColumns: ColumnDef<Charge>[] = [
  {
    id: "Timestamp",
    header: DataTableColumnHeader,
    accessorKey: "timestamp",
    cell: ({ cell }) => format(cell.getValue<Date>(), "yyyy/MM/dd hh:mmaaa"),
  },
  {
    id: "Charge Amount",
    header: DataTableColumnHeader,
    accessorFn: (row) => {
      if ("icp" in row.amount) return row.amount.icp.e8s;
      return row.amount.cycles.e12s;
    },
    cell: ({ cell }) => {
      if ("icp" in cell.row.original.amount) {
        return readableICP({ e8s: Number(cell.getValue<bigint>()) });
      }
      return mapTrillions(Number(cell.getValue<bigint>()), true);
    },
  },
  {
    id: "Canister",
    header: DataTableColumnHeader,
    accessorKey: "canister",
    cell: ({ cell }) => (
      <PrincipalAbbr>{cell.getValue<Principal>().toText()}</PrincipalAbbr>
    ),
  },
  {
    id: "Cycles Topped Up",
    header: DataTableColumnHeader,
    accessorKey: "cycles",
    cell: ({ cell }) => mapTrillions(Number(cell.getValue<bigint>()), true),
  },
];

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data?: TData[];
  isLoading: boolean;
}

function TransactionsTable<TData, TValue>({
  columns,
  data,
  isLoading,
}: DataTableProps<TData, TValue>) {
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "Timestamp", desc: true },
  ]);
  const table = useReactTable<TData>({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    getColumnCanGlobalFilter: () => true,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      pagination,
    },
    initialState: {},
  });

  if (isLoading) return <TableSkeleton />;

  return (
    <>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className="group"
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <CardFooter className="border-t px-6 py-4">
        <DataTablePagination table={table} />
      </CardFooter>
    </>
  );
}

export interface ICPMethodPageProps {
  icpToUsd: (t: Tokens) => number | undefined;
  topupAddress?: string;
  topupBalance?: Tokens;
}
export interface PaymentMethodPageProps extends Omit<BillingProps, "title"> {}

function ICPMethodPage({ topupBalance, icpToUsd }: ICPMethodPageProps) {
  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Payment Method</CardTitle>
          <CardDescription></CardDescription>
        </CardHeader>
        <Separator className="mb-4" />
        <CardContent>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-3">
              <div className="flex items-baseline">
                <div className="w-40 text-sm text-muted-foreground">
                  Current Method
                </div>
                <div className="">ICP Top-Up Account</div>
              </div>
            </div>
          </div>
        </CardContent>
        <Separator className="mb-4" />
        <CardFooter className="flex justify-between gap-3 md:gap-12 items-start md:items-center flex-col md:flex-row">
          <Dialog>
            <DialogTrigger asChild>
              <Button>Change Payment Method</Button>
            </DialogTrigger>
            <DialogContent>
              <Wizard currentMethod="icp-account" />
            </DialogContent>
          </Dialog>
          <div className="text-sm text-muted-foreground text-right leading-tight">
            CycleOps currently accepts ICP or Cycles Ledger tokens.
          </div>
        </CardFooter>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>ICP Top-Up Account</CardTitle>
          <CardDescription>
            A unique account controlled by you and the CycleOps service, which
            funds all your top-ups.
          </CardDescription>
        </CardHeader>
        <Separator className="mb-4" />
        <CardContent>
          <div className="flex flex-col gap-5">
            <div className="flex items-baseline gap-2">
              <TokenAmount
                className="text-3xl py-2"
                amount={topupBalance?.e8s}
                symbol="ICP"
              />
              <span className="text-base text-muted-foreground">
                ≈
                <TokenAmountInUSD amount={topupBalance?.e8s} symbol="ICP" />
              </span>
            </div>
            <div className="flex gap-3 items-center">
              <DepositDialog icpToUsd={icpToUsd} />
              <WithdrawCoordinator />
            </div>
          </div>
        </CardContent>
        <CardFooter>
          <div>
            <small>
              Learn more about{" "}
              <a href="https://docs.cycleops.dev/docs/basics/topop-account">
                the top-up account
              </a>
            </small>
          </div>
        </CardFooter>
      </Card>
    </>
  );
}

export interface CyclesLedgerPageProps {
  provider: WalletProvider;
  walletAddress: string;
  walletBalance: { e12s: number };
  approvalAmount: { e12s: number };
}

export function CyclesLedgerPage({
  provider,
  walletAddress,
  walletBalance,
  approvalAmount,
}: CyclesLedgerPageProps) {
  const conversion = useICPQuote().data;
  const icpToUsd = React.useCallback(
    (t: Tokens) => {
      if (!conversion) return undefined;
      return (Number(t.e8s) / 1e8) * conversion;
    },
    [conversion]
  );
  const topupAddress = useCycleOpsAccountTextQuery().data;
  const topupBalance = useCustomerICPBalanceQuery().data;
  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Payment Method</CardTitle>
          <CardDescription></CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-3">
              <div className="flex items-baseline">
                <div className="w-40 text-sm text-muted-foreground">
                  Current Method
                </div>
                <div className="">Cycles Ledger</div>
              </div>
            </div>
          </div>
        </CardContent>
        <CardFooter className="w-full flex justify-between gap-12 items-center">
          <Dialog>
            <DialogTrigger asChild>
              <Button>Change Payment Method</Button>
            </DialogTrigger>
            <DialogContent>
              <Wizard currentMethod="cycles-ledger" />
            </DialogContent>
          </Dialog>
          <div className="text-sm text-muted-foreground text-right leading-tight">
            CycleOps currently accepts ICP or Cycles Ledger tokens.
          </div>
        </CardFooter>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Cycles Ledger Wallet</CardTitle>
          <CardDescription></CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-3">
              <div className="flex items-baseline">
                <div className="w-40 text-sm text-muted-foreground">
                  Wallet Provider
                </div>
                <div className="capitalize">{provider}</div>
              </div>
              <div className="flex items-baseline">
                <div className="w-40 text-sm text-muted-foreground">
                  Wallet Address
                </div>
                <div className="relative">
                  <div className="absolute top-0 right-0 h-full aspect-square flex items-center justify-center pointer-events-none">
                    <Copy size="12px" />
                  </div>
                  <Hash full className="pr-7">
                    {walletAddress}
                  </Hash>
                </div>
              </div>
              <div className="flex items-baseline">
                <div className="w-40 text-sm text-muted-foreground">
                  Wallet Balance
                </div>
                <div className="">
                  {walletBalance ? (
                    <>{mapTrillions(walletBalance.e12s, true)} Cycles</>
                  ) : (
                    "Loading..."
                  )}
                </div>
              </div>
              <div className="flex items-baseline">
                <div className="w-40 text-sm text-muted-foreground">
                  Allowance
                </div>
                <div className="flex gap-2 items-baseline cursor-pointer">
                  {approvalAmount ? (
                    <>
                      <div>
                        {mapTrillions(approvalAmount.e12s, true)} Cycles
                      </div>
                      <Dialog>
                        <DialogTrigger asChild>
                          <div className="text-sm underline text-blue-600">
                            Update
                          </div>
                        </DialogTrigger>
                        <DialogContent>
                          <UpdateApprovalFlow
                            address={walletAddress}
                            provider={provider}
                          />
                        </DialogContent>
                      </Dialog>
                    </>
                  ) : (
                    "Loading..."
                  )}
                </div>
              </div>
            </div>
          </div>
        </CardContent>
        <CardFooter>
          <Dialog>
            <DialogTrigger asChild>
              <Button>Change Wallet</Button>
            </DialogTrigger>
            <DialogContent>
              <SetupWalletFlow address={walletAddress} provider={provider} />
            </DialogContent>
          </Dialog>
        </CardFooter>
      </Card>

      {topupBalance && topupBalance.e8s > 0n && (
        <Card>
          <CardHeader>
            <CardTitle>ICP Top-Up Account</CardTitle>
            <CardDescription>
              You have switched to the cycles ledger payment method, but you
              still have some ICP in your top-up account.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-5">
              <TokenAmount
                className="text-xl"
                amount={topupBalance?.e8s}
                symbol="ICP"
              />
              <div className="flex gap-3 items-center">
                <DepositDialog icpToUsd={icpToUsd} />
                <WithdrawCoordinator />
              </div>
            </div>
          </CardContent>
          <CardFooter>
            <div>
              <small>
                Learn more about{" "}
                <a href="https://docs.cycleops.dev/docs/basics/topop-account">
                  the top-up account
                </a>
              </small>
            </div>
          </CardFooter>
        </Card>
      )}
    </>
  );
}

export function usePaymentMethod():
  | {
      icp: ICPMethodPageProps;
    }
  | {
      "cycles-ledger": CyclesLedgerPageProps;
    }
  | undefined {
  const result = useCustomerPaymentConfQuery().data;
  const conversion = useICPQuote().data;
  const icpToUsd = React.useCallback(
    (t: Tokens) => {
      if (!conversion) return undefined;
      return (Number(t.e8s) / 1e8) * conversion;
    },
    [conversion]
  );
  const account = result?.cyclesAccount[0];
  const address = account?.account.owner;
  const balance = useCyclesLedgerBalanceQuery({ owner: address });
  const allowance = useCyclesLedgerAllowanceQuery({ owner: address });
  const topupAddress = useCycleOpsAccountTextQuery().data;
  const topupBalance = useCustomerICPBalanceQuery().data;
  const method = (() => {
    if (!result) return undefined;
    if ("icp" in result.paymentMethod) {
      return {
        icp: {
          icpToUsd,
          topupAddress,
          topupBalance,
        } as ICPMethodPageProps,
      };
    }
    if ("cycles" in result.paymentMethod) {
      if (!account) return undefined;
      return {
        "cycles-ledger": {
          provider: "plug" in account.walletProvider ? "plug" : "manual",
          walletAddress: account.account.owner.toText(),
          walletBalance: balance.data !== undefined && {
            e12s: Number(balance.data),
          },
          approvalAmount: allowance.data !== undefined && {
            e12s: Number(allowance.data.allowance),
          },
        } as CyclesLedgerPageProps,
      };
    }
    throw new Error("Unrecognized payment method");
  })();
  return method;
}

export function PaymentMethod({ ...props }: PaymentMethodPageProps) {
  const paymentMethod = usePaymentMethod();

  return (
    <BillingPage {...props} title="Billing">
      <div className="container flex flex-col gap-5">
        {!paymentMethod ? (
          <Spinner />
        ) : "cycles-ledger" in paymentMethod ? (
          <CyclesLedgerPage
            {...paymentMethod["cycles-ledger"]}
            key="cycles-ledger-page"
          />
        ) : (
          <ICPMethodPage {...paymentMethod.icp} key="icp-account-page" />
        )}
      </div>
    </BillingPage>
  );
}
