import { useQuery } from "@tanstack/react-query";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { toast } from "sonner";

import { cyops, icpCoins } from "@/lib/actors";
import { mapOptional, mapPricePerTrillionTo1ICPInCycles } from "@/lib/ic-utils";

import { useNewUIFlag } from "../use-flags";

// Fetch

/// Fetch balance checker principal from CycleOps canister.
async function fetchBalanceChecker() {
  const principal = mapOptional(await cyops.getStatusCheckerPrincipal());
  if (!principal) {
    toast.error("The balancer checker has an issue, staff will be notified.", {
      id: "balance-checker-error",
    });
    throw new Error("Status checker has not been created!");
  }
  return principal.toText();
}

async function fetchBalanceCheckerV2() {
  const principal = mapOptional(await cyops.getStatusCheckerV2Principal());
  if (!principal) {
    toast.error("The balancer checker has an issue, staff will be notified.", {
      id: "balance-checker-error",
    });
    throw new Error("Status checker has not been created!");
  }
  return principal.toText();
}

/// Fetch config status from CycleOps canister.
async function fetchHealthcheck() {
  try {
    const result = await cyops.configCheck();
    if ("ok" in result) return true;
    toast.error(result.err, { id: "healthcheck" });
    return false;
  } catch (e) {
    // Unable to communicate with the cycleops canister, not good!
    toast.error("File a bug with the CycleOps Team: Error Code 615", {
      id: "healthcheck",
    });
    return false;
  }
}

/// Fetch price for 1T cycles in ICP from CycleOps canister.
async function fetchPricePerTrillion() {
  const result = await cyops.pricePerTrillion();
  if ("err" in result) throw new Error(result.err);
  return result.ok;
}

/// Fetch ICP/USD quote from ICP Coins canister.
async function fetchICPQuote() {
  const result = await icpCoins.get_latest();
  const icpQuoteMetadata = result.find((quote) => quote[1] === "ICP/USD");
  if (!icpQuoteMetadata) throw new Error("ICP/USD quote not found");
  const icpQuote = icpQuoteMetadata?.[2];
  return icpQuote;
}

export {
  fetchBalanceChecker,
  fetchBalanceCheckerV2,
  fetchHealthcheck,
  fetchPricePerTrillion,
  fetchICPQuote,
};

// Query

function useBalanceChecker() {
  return useQuery({
    queryKey: ["balance-checker"],
    queryFn: fetchBalanceChecker,
  });
}

function useBalanceCheckerV2() {
  return useQuery({
    queryKey: ["balance-checker-v2"],
    queryFn: fetchBalanceCheckerV2,
  });
}

function useBalanceCheckerFlagged() {
  const newUIFlag = useNewUIFlag();
  const a = useBalanceChecker();
  const b = useBalanceCheckerV2();
  return newUIFlag ? b : a;
}

function useHealthcheckQuery() {
  return useQuery({
    queryKey: ["healthcheck"],
    queryFn: fetchHealthcheck,
  });
}

function usePPTQuote() {
  return useQuery({
    queryKey: ["price-per-trillion"],
    queryFn: fetchPricePerTrillion,
  });
}

function useICPQuote() {
  return useQuery({
    queryKey: ["icp-quote"],
    queryFn: fetchICPQuote,
  });
}

function useCyclesPriceQuery() {
  const ppt = usePPTQuote();
  const icp = useICPQuote();
  return useQuery({
    queryKey: ["cycles-usd-rate"],
    queryFn: () => {
      if (!ppt.data || !icp.data) throw new Error("unreachable");
      const cyclesPerICP = Number(ppt.data.e8s) / 1e8;
      const usdPerTrillionCycles = cyclesPerICP * icp.data;
      return {
        usdPerTrillionCycles,
        icpPerTrillionCycles: ppt.data,
        cyclesPerICP: mapPricePerTrillionTo1ICPInCycles(cyclesPerICP),
      };
    },
    enabled: ppt.isSuccess && icp.isSuccess,
  });
}

export {
  useBalanceChecker as useOldBalanceChecker,
  useBalanceCheckerFlagged as useBalanceChecker,
  useBalanceChecker as useBalanceCheckerV1,
  useBalanceCheckerV2,
  useHealthcheckQuery,
  usePPTQuote,
  useICPQuote,
  useCyclesPriceQuery,
};
