import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { Analytics } from "@vercel/analytics/react";
import { PostHogProvider } from "posthog-js/react";
import React from "react";
import ReactDOM from "react-dom/client";

import ToastProvider from "@/components/provider/toast";
import { queryClient } from "@/hooks/queries";

import App from "./App";
import { SidebarProvider } from "./components/ui/sidebar";
import { SlotProvider } from "./components/ui/slot-context";
import { TooltipProvider } from "./components/ui/tooltip";

import "./index.css";
import "./tailwind.css";
import "@fontsource/roboto-mono";
import "@fontsource-variable/inter";
import "react-toastify/dist/ReactToastify.css";

Sentry.init({
  dsn: "https://6a25f2273a6479e506f361e43e3b369d@o4508342485188608.ingest.us.sentry.io/4508342486564864",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/cycleops\.dev/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={import.meta.env.CYOPS_POSTHOG_KEY}
      options={{
        api_host: import.meta.env.CYOPS_POSTHOG_HOST,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <TooltipProvider>
          <SidebarProvider>
            <SlotProvider>
              <Analytics />
              <App />
              <ToastProvider />
            </SlotProvider>
          </SidebarProvider>
        </TooltipProvider>
      </QueryClientProvider>
    </PostHogProvider>
  </React.StrictMode>
);
