import { FilterComparator } from "./types";

export const FILTER_COMPARATORS = {
  select: ["is", "is not"],
  multiselect: ["includes one of", "includes all", "does not include"],
  string: ["is", "is not"],
  date: ["is before", "is after", "equals"],
  number: ["greater than", "less than"],
  boolean: ["is", "is not"],
} satisfies Record<string, FilterComparator[]>;

export const DFX_PARALLEL_COMMANDS = 20;
export const DFX_COMMAND_REST_INTERVAL_SECONDS = 5;
