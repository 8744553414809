import BigNumber from "bignumber.js";
import { useMemo } from "react";
import { toast } from "sonner";

import { Tokens } from "common/declarations/nns-ledger/nns-ledger.did.d";

import { fromE8s } from "@/lib/ic-utils";
import useThemeStore from "@/state/stores/theme";

/// Copy some text to the user's clipboard.
export function copy(string?: string) {
  if (!string) return;
  toast.info("Copied to clipboard");
  navigator.clipboard.writeText(string);
}

export function displayIcp(amount: Tokens, decimals = 2) {
  return `${fromE8s(amount).toFixed(decimals)}`;
}

export function stringToIcp(amount: string): Tokens {
  if (!amount) return { e8s: BigInt(0) };

  const floatAmount = new BigNumber(amount);
  if (floatAmount.isNaN()) {
    throw new Error("Invalid amount");
  }

  // Multiply by 1e8 and round down to avoid floating point issues
  const e8s = floatAmount.multipliedBy(1e8).integerValue(BigNumber.ROUND_DOWN);

  return { e8s: BigInt(e8s.toFixed(0)) };
}

/// Format a user input string to be a valid float.
/// Disable intermediate values to remove leading/trailing dots and commas.
export function maskFloatInput(
  input: string,
  options?: {
    decimals?: number;
    intermediate?: boolean;
    commas?: boolean;
  }
): string {
  const { decimals = 8, intermediate = true, commas = false } = options || {};
  let output = input;

  // Remove all dots except the first one
  const match = output.match(/\./g);
  if (match && match.length > 1) {
    output = output.replace(/\./g, (match2, offset) =>
      offset === 1 ? match2 : ""
    );
  }

  // Remove leading zeros
  output = output.replace(/^0+(?=\d)/, "");

  // Restrict input to only numbers, dots, and commas
  output = output.replace(/[^0-9,.]/g, "");

  // Split input by dot to separate whole number and decimal parts
  const parts = output.split(".");

  // Limit decimal places if necessary
  if (parts[1] && parts[1].length > decimals) {
    parts[1] = parts[1].substring(0, decimals);
    output = parts.join(".");
  }

  // Add commas to separate every three digits in the whole number portion
  if (commas) {
    if (parts[0]) {
      parts[0] = parts[0]
        .toString()
        .replaceAll(",", "")
        .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,");
      output = parts.join(".");
    } else {
      output = output
        .toString()
        .replaceAll(",", "")
        .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,");
    }
  }

  if (!intermediate) {
    output = output.replace(/^[,.]+|[,.]+$/g, "");
  }
  return output;
}

const formatter = new Intl.RelativeTimeFormat("en", { style: "narrow" });
const DIVISIONS: {
  amount: number;
  name: Intl.RelativeTimeFormatUnit;
}[] = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
];

export function relative(date: Date): string {
  let duration = (date.getTime() - new Date().getTime()) / 1000;

  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (!division) throw new Error(`Array out of range: ${i}`);
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
  throw new Error();
}

export function daysIn(from: Date, to: Date) {
  return Math.round((to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24));
}

export function useColors() {
  const { theme } = useThemeStore();
  const [
    chrome1,
    chrome2,
    chrome3,
    chrome4,
    chrome5,
    chrome6,
    healthy,
    unhealthy,
    warning,
    pending,
    link,
  ] = useMemo(
    () => [
      ...Array(6)
        .fill(0)
        .map((_, i) =>
          getComputedStyle(document.documentElement).getPropertyValue(
            `--chrome-${i + 1}`
          )
        ),
      ...["healthy", "low balance", "warning", "pending", "link"].map((x) =>
        getComputedStyle(document.documentElement).getPropertyValue(`--${x}`)
      ),
    ],
    [theme]
  );
  return {
    chrome1: chrome1!,
    chrome2: chrome2!,
    chrome3: chrome3!,
    chrome4: chrome4!,
    chrome5: chrome5!,
    chrome6: chrome6!,
    healthy: healthy!,
    unhealthy: unhealthy!,
    warning: warning!,
    pending: pending!,
    link: link!,
  };
}
