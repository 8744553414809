import { useEffect } from "react";

import {
  useCustomerMetadataQuery,
  useIndividualMetadataQuery,
} from "@/hooks/queries/customer";
import { cn } from "@/lib/ui-utils";

import { Team, TeamAvatar } from "../team-switcher";
import { SidebarMenuButton, SidebarTrigger, useSidebar } from "../ui/sidebar";
import { Slot } from "../ui/slot-context";
import { BaseLayout } from "./layout";
import { NavUser } from "./sidebar";

export default function LayoutFocus({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { open, setOpen } = useSidebar();

  // Force sidebar to be collapsed when this layout is mounted
  useEffect(() => {
    const prev = open;
    setOpen(false, false);
    return () => setOpen(prev, false);
  }, []);

  return (
    <BaseLayout variant="focus">
      <Slot name="sidebar-header">
        <TeamIcon />
      </Slot>
      <Slot name="sidebar-content">
        <></>
      </Slot>
      <Slot name="sidebar-footer">
        <NavUser />
      </Slot>
      {children}
    </BaseLayout>
  );
}

function TeamIcon() {
  const userQuery = useIndividualMetadataQuery();
  const activeAccountQuery = useCustomerMetadataQuery();

  const user = userQuery.data
    ? ({
        id: userQuery.data.username,
        name: "My Account",
        logo: userQuery.data.logoUrl,
      } satisfies Team)
    : undefined;

  const active = activeAccountQuery.data
    ? ({
        id: activeAccountQuery.data.username,
        name: activeAccountQuery.data.displayName || "My Account",
        logo: activeAccountQuery.data.logoUrl,
      } satisfies Team)
    : undefined;

  return (
    <SidebarMenuButton
      size="lg"
      className="cursor-default"
      tooltip={`Active: ${active?.name}`}
    >
      <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
        <TeamAvatar name={active?.name} logo={active?.logo} />
      </div>
      <div className="grid flex-1 text-left text-sm leading-tight">
        <span className="truncate font-semibold">
          {active?.id === user?.id ? "My Account" : active?.name}
        </span>
      </div>
    </SidebarMenuButton>
  );
}

export function FocusTopbar({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <header
      className={cn("flex flex-col shrink-0 items-center gap-0", className)}
    >
      <div
        className={cn(
          "flex items-center gap-2 px-2 pr-0 justify-between w-full min-h-16 mb-1",
          "max-md:px-3",
          "h-12 min-h-max"
        )}
      >
        <SidebarTrigger className="md:hidden" />
        {children}
      </div>
    </header>
  );
}
