import { MoreVertical } from "lucide-react";
import { useState } from "react";

import { Button } from "./button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./sheet";

interface PageActionsProps {
  children: React.ReactNode;
  title?: string;
}

export function PageActions({ children, title = "Actions" }: PageActionsProps) {
  const [open, setOpen] = useState(false);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild className="md:hidden">
        <Button variant="outline" size="sm" className="gap-2">
          <MoreVertical className="h-4 w-4" />
          More
        </Button>
      </SheetTrigger>
      <SheetContent className="w-full sm:max-w-sm">
        <SheetHeader>
          <SheetTitle>{title}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-2">{children}</div>
      </SheetContent>
    </Sheet>
  );
}
