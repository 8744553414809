"use client";

import {
  BookOpen,
  LifeBuoy,
  Send,
  BadgeCheck,
  ChevronsUpDown,
  CreditCard,
  LogOut,
  Settings,
  Box,
  Cylinder,
  ChevronRight,
} from "lucide-react";
import * as React from "react";
import { useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Feedback } from "@/components/feedback";
import { Support } from "@/components/support";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuLabel,
} from "@/components/ui/dropdown-menu";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarGroup,
  SidebarMenuAction,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  useSidebar,
  SidebarGroupContent,
  SidebarRail,
} from "@/components/ui/sidebar";
import { Skeleton } from "@/components/ui/skeleton";
import { SlotOutlet } from "@/components/ui/slot-context";
import {
  useCustomerEmailQuery,
  useIndividualMetadataQuery,
} from "@/hooks/queries/customer";
import { useCurrentTeamQuery, useRoute } from "@/hooks/queries/team";
import { cn } from "@/lib/ui-utils";
import { useIdp } from "@/state/stores/idp";

function UserItem() {
  const user = useIndividualMetadataQuery();
  const email = useCustomerEmailQuery();

  if (user.data)
    return (
      <>
        <Avatar className="h-8 w-8 rounded-lg">
          <AvatarImage src={user.data.logoUrl} alt={user.data.displayName} />
          <AvatarFallback className="rounded-lg">
            {user.data.displayName?.slice(0, 2)}
          </AvatarFallback>
        </Avatar>
        <div className="grid flex-1 text-left text-sm leading-tight">
          <span className="truncate font-semibold">
            {user.data.displayName}
          </span>
          <span className="truncate text-xs">{email.data?.address}</span>
        </div>{" "}
      </>
    );

  return <Skeleton className="size-8 rounded-lg" />;
}

export function NavUser() {
  const { isMobile } = useSidebar();

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-muted/50 data-[state=open]:ring-1 data-[state=open]:ring-border/80 hover:bg-muted transition-colors duration-100"
              tooltip="User Account"
            >
              <UserItem />
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side={isMobile ? "bottom" : "bottom"}
            align="end"
            sideOffset={4}
          >
            <DropdownMenuLabel className="p-0 font-normal">
              <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                <UserItem />
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem
                asChild
                className="hover:bg-muted transition-colors duration-100"
              >
                <Link to="/app/personal/settings">
                  <BadgeCheck className="mr-2 h-4 w-4" />
                  Account Settings
                </Link>
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <LogoutButton />
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}

function LogoutButton() {
  const { disconnect: d } = useIdp();
  const navigate = useNavigate();
  return (
    <DropdownMenuItem
      className="hover:bg-muted transition-colors duration-100"
      onClick={() => d().then(() => navigate("/"))}
    >
      <LogOut className="mr-2 h-4 w-4" />
      Log out
    </DropdownMenuItem>
  );
}

function useNavSecondary() {
  return useMemo(() => {
    return [
      {
        title: "Documentation",
        element: (
          <a
            href="https://docs.cycleops.dev/docs/getting-started"
            target="_blank"
          >
            <BookOpen />
            <span>Documentation</span>
          </a>
        ),
      },
      {
        title: "Support",
        element: (
          <Support>
            <SidebarMenuButton size="sm" tooltip="Support">
              <LifeBuoy />
              <span>Support</span>
            </SidebarMenuButton>
          </Support>
        ),
      },
      {
        title: "Feedback",
        element: (
          <Feedback>
            <SidebarMenuButton size="sm" tooltip="Feedback">
              <Send />
              <span>Feedback</span>
            </SidebarMenuButton>
          </Feedback>
        ),
      },
    ];
  }, []);
}

export function NavSecondary() {
  const items = useNavSecondary();
  return (
    <SidebarGroup>
      <SidebarGroupContent>
        <SidebarMenu>
          {items.map((item) => (
            <SidebarMenuItem key={item.title}>
              <SidebarMenuButton asChild size="sm" tooltip={item.title}>
                {item.element}
              </SidebarMenuButton>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
}

function useNavMain() {
  const team = useCurrentTeamQuery();
  return useMemo(() => {
    return [
      {
        title: "Canisters",
        url: "/canisters",
        isActive: true,
        icon: Cylinder,
      },
      {
        title: "Projects",
        url: "/projects",
        icon: Box,
      },
      {
        title: "Settings",
        url: "/settings",
        icon: Settings,
        items: [
          {
            title: "General",
            url: "/settings",
          },
          {
            title: "Alerts",
            url: "/settings/alerts",
          },
          {
            title: "Experiments",
            url: "/settings/experiments",
          },
          ...(team.data
            ? [{ title: "Members", url: `/settings/members` }]
            : []),
        ],
      },
      {
        title: "Billing",
        url: "/billing",
        icon: CreditCard,
        items: [
          {
            title: "Payment Method",
            url: "/billing",
          },
          {
            title: "History",
            url: "/billing/history",
          },
        ],
      },
    ];
  }, [team]);
}

export function NavMain() {
  const items = useNavMain();
  const route = useRoute();
  const sidebar = useSidebar();
  const { pathname } = useLocation();
  const isActive = (url: string, strict = false) => {
    const normalizedPathname = pathname.replace(/\/team\/[^/]+/, "");
    const routedUrl = route(url);
    const normalizedRoutedUrl = routedUrl.replace(/\/team\/[^/]+/, "");
    const isStrict = strict
      ? normalizedRoutedUrl === normalizedPathname
      : normalizedPathname.startsWith(normalizedRoutedUrl);
    return isStrict;
  };
  const isChildActive = (item: { items?: { url: string }[] }) =>
    item.items?.some((subItem) => isActive(subItem.url, true));

  return (
    <SidebarGroup className="gap-4">
      <SidebarMenu>
        {items.map((item) => {
          const isParentActive = isActive(item.url);
          const hasActiveChild = isChildActive(item);
          const shouldHighlight =
            (isParentActive && !hasActiveChild) ||
            (sidebar.state === "collapsed" && hasActiveChild);

          return (
            <Collapsible
              key={item.title}
              asChild
              defaultOpen={isActive(item.url)}
            >
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  tooltip={item.title}
                  className={cn(
                    "bg-muted/0 transition-colors duration-100",
                    shouldHighlight && "bg-muted/50 ring-1 ring-border/80",
                    "hover:bg-muted"
                  )}
                >
                  <Link to={route(item.url)}>
                    <item.icon />
                    <span>{item.title}</span>
                  </Link>
                </SidebarMenuButton>
                {item.items?.length ? (
                  <>
                    <CollapsibleTrigger asChild>
                      <SidebarMenuAction className="data-[state=open]:rotate-90">
                        <ChevronRight />
                        <span className="sr-only">Toggle</span>
                      </SidebarMenuAction>
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      <SidebarMenuSub>
                        {item.items?.map((subItem) => (
                          <SidebarMenuSubItem key={subItem.title}>
                            <SidebarMenuSubButton
                              asChild
                              className={cn(
                                "bg-muted/0 transition-colors duration-100",
                                isActive(subItem.url, true) &&
                                  "bg-muted/50 ring-1 ring-border/80",
                                "hover:bg-muted"
                              )}
                            >
                              <Link to={route(subItem.url)}>
                                <span>{subItem.title}</span>
                              </Link>
                            </SidebarMenuSubButton>
                          </SidebarMenuSubItem>
                        ))}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </>
                ) : null}
              </SidebarMenuItem>
            </Collapsible>
          );
        })}
      </SidebarMenu>
    </SidebarGroup>
  );
}

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  return (
    <Sidebar variant="inset" {...props} collapsible="icon">
      <SidebarHeader>
        <SlotOutlet name="sidebar-header" />
      </SidebarHeader>
      <SidebarContent>
        <SlotOutlet name="sidebar-content" />
      </SidebarContent>
      <SidebarFooter>
        <SlotOutlet name="sidebar-footer" />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
