import * as React from "react";

import { AppNotifications } from "@/components/layout/notifications";
import { NavMain, NavSecondary, NavUser } from "@/components/layout/sidebar";
import { TeamSwitcher } from "@/components/team-switcher";
import { useSidebar, SidebarTrigger } from "@/components/ui/sidebar";
import { Slot } from "@/components/ui/slot-context";
import { cn } from "@/lib/ui-utils";

import { BaseLayout } from "./layout";

interface TopBarProps {
  children?: React.ReactNode;
  className?: string;
}

export function TopBar({ children, className }: TopBarProps) {
  const sidebar = useSidebar();

  return (
    <header
      className={cn("flex flex-col shrink-0 items-center gap-0", className)}
    >
      <div
        className={cn(
          "flex items-center gap-2 px-2 pr-0 justify-between w-full min-h-16 mb-1",
          "max-md:px-3",
          !sidebar.open && "h-12 min-h-max"
        )}
      >
        <SidebarTrigger className="md:hidden" />
        {children}
      </div>
    </header>
  );
}

export function Layout({ children }: { children?: React.ReactNode }) {
  return (
    <BaseLayout>
      <Slot name="sidebar-header">
        <TeamSwitcher />
      </Slot>
      <Slot name="sidebar-content">
        <NavMain />
        <AppNotifications />
        <NavSecondary />
      </Slot>
      <Slot name="sidebar-footer">
        <NavUser />
      </Slot>
      {children}
    </BaseLayout>
  );
}
