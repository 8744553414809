import LayoutFocus, { FocusTopbar } from "@/components/layout/layout-focus";
import { SiteBreadcrumb } from "@/components/site-breadcrumb";
import { Button } from "@/components/ui/button";

export default function Test() {
  return (
    <LayoutFocus>
      <FocusTopbar>
        <SiteBreadcrumb
          items={[{ title: "Test" }, { title: "Test 2" }, { title: "Test 3" }]}
        />
        <Button>Abort</Button>
      </FocusTopbar>
    </LayoutFocus>
  );
}
