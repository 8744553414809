import { differenceInDays, format } from "date-fns";
import { Loader2 } from "lucide-react";
import { useMemo } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from "recharts";

import { TC } from "@/components/tc";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useAggregateTopupsInsight } from "@/hooks/queries/insights";
import { useChargesQuery } from "@/hooks/queries/transactions";
import { cn } from "@/lib/ui-utils";

import { ChartContainer, ChartTooltip, ChartConfig } from "../ui/chart";
import { CustomTooltip } from "./topup";

export function AggregateTopupChart({ className }: { className?: string }) {
  const query = useChargesQuery({ limit: 1000 });

  const insight = useAggregateTopupsInsight();

  const data = useMemo(
    () =>
      insight.points?.map(([date, amount]) => ({
        timestamp: date.getTime(),
        amount: (amount ?? 0) / 1e12,
      })),
    [insight.points]
  );

  const startDate = data?.[0]?.timestamp;
  const endDate = data?.[data.length - 1]?.timestamp;
  const daysDifference =
    startDate && endDate ? differenceInDays(endDate, startDate) : undefined;

  const formatString =
    daysDifference && daysDifference < 3 ? "MMM dd HH:mm" : "MMM dd";

  const totalAmount = data?.reduce((sum, d) => sum + (d.amount ?? 0), 0);

  const tickFormatter = (value: string) => {
    const date = new Date(value);
    return format(date, formatString);
  };

  const chartConfig = {
    amount: {
      label: "Amount",
      color: "var(--healthy)",
    },
  } satisfies ChartConfig;

  return (
    <>
      <Card className={cn("bg-table", className)}>
        <CardHeader className="px-2 py-2">
          <CardTitle className="text-sm flex justify-between">
            Top-ups
            {!!totalAmount && (
              <div className="flex items-center gap-1">
                <TC value={totalAmount} includeUnit />
                 <span className="text-xs">
                  ($
                  {(Number(totalAmount) * 1.33).toFixed(2).toLocaleString()})
                </span>
                <span>over {daysDifference} days</span>
              </div>
            )}
          </CardTitle>
        </CardHeader>
        <CardContent className="p-0 lg:p-2 pb-0 relative">
          {query.isLoading ? (
            <div className="max-h-[200px] min-h-[120px] aspect-video absolute inset-0 flex items-center justify-center">
              <Loader2 className="h-4 w-4 animate-spin" />
            </div>
          ) : query.data?.length === 0 ? (
            <div className="max-h-[200px] min-h-[120px] aspect-video absolute inset-0 flex items-center justify-center text-xs italic text-muted">
              Insufficient data
            </div>
          ) : (
            <ChartContainer
              config={chartConfig}
              className="max-h-[200px] min-h-[120px] w-full"
            >
              <BarChart
                data={data}
                margin={{
                  left: 20,
                  right: 0,
                  bottom: 0,
                  top: 20,
                }}
              >
                <CartesianGrid vertical={false} />
                <XAxis
                  dataKey="timestamp"
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  tickFormatter={tickFormatter}
                />
                <YAxis
                  yAxisId="right"
                  key="amount"
                  tickLine={true}
                  axisLine={false}
                  tickFormatter={(value) => `${value} TC`}
                  orientation="right"
                />
                <ChartTooltip
                  cursor={{
                    strokeWidth: 2,
                  }}
                  content={<CustomTooltip />}
                />
                <Bar
                  yAxisId="right"
                  dataKey="amount"
                  fill="var(--healthy)"
                  isAnimationActive={false}
                  barSize={20}
                />
              </BarChart>
            </ChartContainer>
          )}
        </CardContent>
      </Card>
    </>
  );
}
