import {
  Filter,
  FolderIcon,
  FuelIcon,
  HeartPulseIcon,
  MemoryStickIcon,
  TagsIcon,
  WalletIcon,
} from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useCanisterTableQuery } from "@/hooks/queries/canisters";
import { useCanisterTableStore } from "@/hooks/stores/canister-table-store";

import { FILTER_COMPARATORS } from "./constants";
import { FilterItem } from "./filter-item";
import { FilterComponent } from "./selected-filter";
import { FilterValue, CanisterTableFilters } from "./types";

interface CanisterListFiltersProps {
  className?: string;
  children?: React.ReactNode;
}

export function CanisterListFilters({
  className,
  children,
}: CanisterListFiltersProps) {
  const {
    filters: selectedFilters,
    setFilters: setSelectedFilters,
    filterMode,
    setFilterMode,
  } = useCanisterTableStore();
  const [activeFilter, setActiveFilter] = useState<FilterValue | null>(null);
  const [open, setOpen] = useState(false);
  const canisters = useCanisterTableQuery();

  const uniqueProjects = useMemo(() => {
    if (!canisters.data) return [];
    return Array.from(
      new Set(canisters.data.map((item) => item.project))
    ).filter(Boolean) as string[];
  }, [canisters.data]);

  const uniqueTags = useMemo(() => {
    if (!canisters.data) return [];
    return Array.from(
      new Set(canisters.data.flatMap((item) => item.tags))
    ).filter(Boolean) as string[];
  }, [canisters.data]);

  const canisterFilter: CanisterTableFilters = useMemo(
    () => ({
      project: {
        label: "Project",
        field: "Project",
        comparator: "is",
        type: "select",
        options: uniqueProjects,
        icon: FolderIcon,
      },
      tags: {
        label: "Tags",
        field: "Tags",
        comparator: "includes all",
        type: "multiselect",
        options: uniqueTags,
        icon: TagsIcon,
      },
      status: {
        label: "Status",
        field: "Status",
        comparator: "is",
        type: "select",
        options: ["pending", "healthy", "low balance", "frozen"],
        icon: HeartPulseIcon,
      },
      memorySize: {
        label: "Memory Size",
        field: "Memory Size",
        comparator: "greater than",
        type: "number",
        options: [10],
        icon: MemoryStickIcon,
        unit: "MB",
      },
      balance: {
        label: "Cycles balance",
        field: "Balance",
        comparator: "less than",
        type: "number",
        options: [5],
        icon: WalletIcon,
        unit: "T",
      },
      burnPerDay: {
        label: "Burned (24 hours)",
        field: "Burn (24hr)",
        comparator: "greater than",
        type: "number",
        options: [1],
        icon: FuelIcon,
        unit: "T",
      },
      burnTotal: {
        label: "Burned (30 days)",
        field: "Burn (30d)",
        comparator: "greater than",
        type: "number",
        options: [1],
        icon: FuelIcon,
        unit: "T",
      },
    }),
    [uniqueProjects]
  );

  const trigger = children ?? (
    <Button size="sm" variant="ghost" className="gap-2 bg-muted/0">
      <Filter className="w-4 h-4" />
    </Button>
  );

  const handleFilterChange = (index: number, updatedFilter: FilterValue) => {
    const updatedFilters = [...selectedFilters];
    updatedFilters[index] = updatedFilter;

    setSelectedFilters(updatedFilters);
  };

  return (
    <div className="flex gap-4 items-center">
      <DropdownMenu
        open={open}
        onOpenChange={(value) => {
          setOpen(value);
          if (!value) setActiveFilter(null);
        }}
      >
        <DropdownMenuTrigger asChild>{trigger}</DropdownMenuTrigger>
        {!activeFilter ? (
          <DropdownMenuContent align="start" className="w-52">
            <DropdownMenuGroup>
              {Object.entries(canisterFilter).map(([field, filter]) => (
                <DropdownMenuItem
                  className="cursor-pointer"
                  key={field}
                  onSelect={(e) => {
                    if (
                      filter.type === "select" ||
                      filter.type === "multiselect"
                    ) {
                      e.preventDefault();
                      e.stopPropagation();
                      setActiveFilter(filter);
                    } else {
                      setSelectedFilters([
                        ...selectedFilters,
                        { ...filter, value: filter.options[0] },
                      ]);
                    }
                  }}
                >
                  {/* {<filter.icon />} */}
                  <label
                    htmlFor={field}
                    className="text-sm font-medium capitalize"
                  >
                    {filter.label}
                  </label>
                  <DropdownMenuShortcut>
                    {field.charAt(0).toUpperCase()}
                  </DropdownMenuShortcut>
                </DropdownMenuItem>
              ))}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        ) : activeFilter.type === "select" ? (
          <DropdownMenuContent align="start" className="w-52">
            <FilterItem
              field={activeFilter.field}
              filter={activeFilter}
              onSelect={(newFilter) => {
                setSelectedFilters([...selectedFilters, newFilter]);
              }}
              onClose={() => {
                setOpen(false);
                setActiveFilter(null);
              }}
            />
          </DropdownMenuContent>
        ) : activeFilter.type === "multiselect" ? (
          <DropdownMenuContent align="start" className="w-52">
            <FilterItem
              field={activeFilter.field}
              filter={activeFilter}
              onSelect={(newFilter) => {
                const newValue = newFilter.value as string;
                // weird error, because we update the const at line 228;
                // eslint-disable-next-line prefer-const
                let existingFilter = selectedFilters.find(
                  (f) => f.field === activeFilter.field
                );

                if (!existingFilter) {
                  setSelectedFilters([
                    ...selectedFilters,
                    { ...newFilter, values: [newValue.toString()] },
                  ]);
                  return;
                }

                let existingValues = Array.isArray(existingFilter.values)
                  ? existingFilter.values
                  : [];

                if (!existingValues.includes(newValue))
                  existingValues.push(newValue);
                else
                  existingValues = existingValues.filter((v) => v !== newValue);

                const existingIndex = selectedFilters?.findIndex(
                  (f) => f.field === activeFilter.field
                );

                existingFilter.values = existingValues;

                handleFilterChange(existingIndex, existingFilter);
              }}
              onClose={() => {
                setOpen(false);
                setActiveFilter(null);
              }}
            />
          </DropdownMenuContent>
        ) : null}
      </DropdownMenu>
      {selectedFilters.length > 1 && (
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="sm"
            className={
              filterMode === "matchAll"
                ? "bg-primary text-primary-foreground text-xs"
                : "text-xs"
            }
            onClick={() => {
              const newMode =
                filterMode === "matchAll" ? "matchAny" : "matchAll";
              setFilterMode(newMode);
              setSelectedFilters(
                selectedFilters.map((f) => ({
                  ...f,
                  mode: newMode,
                }))
              );
            }}
          >
            {filterMode === "matchAll" ? "Match All" : "Match Any"}
          </Button>
        </div>
      )}
      <div className="flex flex-wrap gap-1 py-1">
        {selectedFilters.map((filter, index) => {
          const availableComparators = FILTER_COMPARATORS[filter.type];
          return (
            <FilterComponent
              key={index}
              filter={filter}
              onChange={(updatedFilter) =>
                handleFilterChange(index, updatedFilter)
              }
              onRemove={() => {
                setSelectedFilters([
                  ...selectedFilters.slice(0, index),
                  ...selectedFilters.slice(index + 1),
                ]);
              }}
              comparators={availableComparators}
              values={
                filter.type === "select" && "options" in filter
                  ? filter.options
                  : []
              }
            />
          );
        })}
      </div>
    </div>
  );
}
