import { Toaster } from "sonner";

export default function ToastProvider() {
  return (
    <Toaster
      theme="system"
      visibleToasts={9}
      duration={10000}
      toastOptions={{
        classNames: {
          default: "bg-neutral-800 text-white",
          success: "!bg-green-800",
          error: "!bg-red-800",
          info: "!bg-blue-800",
          warning: "!bg-orange-800",
        },
      }}
    />
  );
}
