import { Tokens } from "@dfinity/ledger-icp";
import { Principal } from "@dfinity/principal";
import {
  DragDropContext,
  Droppable,
  Draggable,
  OnDragEndResponder,
  DraggableStyle,
} from "@hello-pangea/dnd";
import { AvatarFallback } from "@radix-ui/react-avatar";
import {
  ColumnDef,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ArrowUpRightSquare,
  GripVertical,
  PencilIcon,
  PlusCircle,
  Settings,
  Trash2Icon,
  TrashIcon,
} from "lucide-react";
import React, { ComponentPropsWithoutRef } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import { CanisterConfig } from "common/declarations/cycleops/cycleops.did.d";

import { App, AppContents, AppFooter, AppHeader } from "@/components/app";
import { CreateProjectDialog } from "@/components/create-project-dialog";
import IcpAlert from "@/components/icp-alert-settings";
import MemoryAlerts from "@/components/memory-alerts";
import PageHeader from "@/components/page-header";
import PrincipalAbbr from "@/components/principal-abbr";
import { SidebarNav } from "@/components/sidebar-nav";
import TableSkeleton from "@/components/table-skeleton";
import TopupAlerts from "@/components/topup-alerts";
import { useTransferProjectDialog } from "@/components/transfer-project-dialog";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import Hashatar from "@/components/ui/hashatar";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import EmailConf from "@/coordination/settings/email-config";
import {
  CustomerMetadataForm,
  TeamCreationForm,
} from "@/hooks/forms/customer-metadata";
import { useCanistersQuery } from "@/hooks/queries/canisters";
import {
  NotificationSettings,
  useCustomerMetadataQuery,
  useCustomerNotificationSettingsMutation,
  useCustomerNotificationSettingsQuery,
  useCustomerPaymentConfQuery,
} from "@/hooks/queries/customer";
import {
  useCanisterProjectMutation,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useProjectsQuery,
  useRenameProjectMutation,
  useTransferProjectMutation,
} from "@/hooks/queries/projects";
import {
  Member,
  Role,
  useCallerTeamsQuery,
  useCurrentTeamQuery,
  useRoute,
  useTeamAddMemberMutation,
  useTeamDeleteMutation,
  useTeamMemberPermissionMutation,
  useTeamMemberRemovalMutation,
  useTeamMembersQuery,
} from "@/hooks/queries/team";
import { useCreateProjectDialogStore } from "@/hooks/stores/create-project-dialog-store";
import { mapOptional } from "@/lib/ic-utils";
import { cn } from "@/lib/ui-utils";
import { useIdp } from "@/state/stores/idp";

import CyclesAllowanceAlert from "../cycles-allowance-alert-settings";
import { DataTableColumnHeader } from "../data-table/column-header";
import { ExperimentalFeatures } from "../experimental-features";

export interface SettingsProps extends ComponentPropsWithoutRef<"div"> {
  title: string;
  headChildren?: React.ReactNode;
}

export default function SettingsPage({
  children,
  headChildren,
  className,
  ...props
}: SettingsProps) {
  const team = useCurrentTeamQuery();
  const sidebarNavItems = [
    {
      title: "General",
      href: `/app/settings/`,
    },
    {
      title: "Alerts",
      href: `/app/settings/alerts`,
    },
    {
      title: "Projects",
      href: `/app/settings/projects`,
    },
    {
      title: "Experiments",
      href: `/app/settings/experiments`,
    },
    ...(team.data ? [{ title: "Members", href: `/app/settings/members` }] : []),
  ];

  return (
    <App {...props}>
      <AppHeader {...props} />
      <AppContents {...props}>
        <PageHeader {...props} children={headChildren} />
        <main className="container md:pb-16 h-full">
          <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-12 lg:space-y-0 h-full">
            <aside className="md:-mx-4 lg:w-1/5 py-6 md:pl-4">
              <SidebarNav items={sidebarNavItems} />
            </aside>
            <div className={cn("flex-1 pt-2 md:pt-6", className)}>
              {children}
            </div>
          </div>
        </main>
      </AppContents>
      <AppFooter {...props} />
    </App>
  );
}

interface DefaultSettingsProps {
  topupAlertSettings?: NotificationSettings;
  topupAlertSaveHandler: (settings: NotificationSettings) => void;
  notificationsLoading?: boolean;

  lowICPBalanceAlertSettings: { threshold: number; enabled: boolean };
  lowICPBalanceAlertSaveHandler: (settings: {
    threshold: number;
    enabled: boolean;
  }) => void;
  lowICPBalanceAlertLoading?: boolean;

  lowCyclesAllowanceAlertSettings: { threshold: number; enabled: boolean };
  lowCyclesAllowanceAlertSaveHandler: (settings: {
    threshold: number;
    enabled: boolean;
  }) => void;
  lowCyclesAllowanceAlertLoading?: boolean;

  memoryAlertSettings: { notifyOnMemoryThresholdReached?: boolean };
  memoryAlertSaveHandler: (enabled: boolean) => void;
  memoryAlertLoading?: boolean;
}

export function DefaultSettings() {
  // TODO: This component is a mess.
  const paymentConf = useCustomerPaymentConfQuery();
  const { mutateAsync: updateNotificationSettings, isPending: mutateLoading } =
    useCustomerNotificationSettingsMutation();

  const { data: notificationSettings, isPending: queryLoading } =
    useCustomerNotificationSettingsQuery();

  const handleNotificationSettingsSave = React.useCallback(
    async (settings: NotificationSettings) => {
      if (!notificationSettings) return;
      await updateNotificationSettings({
        ...notificationSettings,
        ...settings,
      });
    },
    [notificationSettings]
  );

  const loading = mutateLoading || queryLoading;

  const handleIcpAlertSave = React.useCallback(
    async ({ enabled, threshold }: { threshold: number; enabled: boolean }) => {
      if (!notificationSettings) return;
      // threshold validation
      if (enabled && threshold < 0.1) {
        toast.error(
          "Must set a low ICP balance threshold greater than 0.1 ICP"
        );
        return;
      }
      const notifyOnICPBelow: Tokens | undefined =
        enabled && threshold
          ? { e8s: BigInt(threshold * 100_000_000) }
          : undefined;
      await updateNotificationSettings({
        ...notificationSettings,
        notifyOnICPBelow,
      });
    },
    [notificationSettings]
  );

  const lowCyclesAllowanceAlertSaveHandler = React.useCallback(
    async ({ enabled, threshold }: { threshold: number; enabled: boolean }) => {
      if (!notificationSettings) return;
      // threshold validation
      if (enabled && threshold < 0.1) {
        toast.error(
          "Must set a low cycles balance threshold greater than 0.1 cycles"
        );
        return;
      }
      const notifyOnCyclesApprovalBalanceBelow =
        enabled && threshold ? BigInt(threshold * 1e12) : undefined;
      await updateNotificationSettings({
        ...notificationSettings,
        notifyOnCyclesApprovalBalanceBelow,
      });
    },
    [notificationSettings]
  );

  const handleMemoryAlertSave = React.useCallback(
    async (enabled: boolean) => {
      if (!notificationSettings) return;
      await updateNotificationSettings({
        ...notificationSettings,
        notifyOnMemoryThresholdReached: enabled,
      });
    },
    [notificationSettings]
  );

  // derived low icp balance settings prop
  const lowICPBalanceAlertSettings =
    notificationSettings?.notifyOnICPBelow === undefined
      ? { enabled: false, threshold: 0 }
      : {
          enabled: true,
          threshold:
            Number(notificationSettings?.notifyOnICPBelow.e8s) / 100_000_000,
        };

  // derived cycles low balance settings prop
  const lowCyclesAllowanceAlertSettings =
    notificationSettings?.notifyOnCyclesApprovalBalanceBelow === undefined
      ? { enabled: false, threshold: 0 }
      : {
          enabled: true,
          threshold:
            Number(notificationSettings?.notifyOnCyclesApprovalBalanceBelow) /
            1e12,
        };

  return (
    <SettingsPage title="Settings">
      <div className="flex flex-col gap-5 container">
        <>
          <EmailConf />
          <TopupAlerts
            settings={notificationSettings}
            handler={handleNotificationSettingsSave}
            loading={loading}
          />
          {paymentConf.data?.paymentMethod ? (
            "icp" in paymentConf.data.paymentMethod ? (
              <IcpAlert
                settings={lowICPBalanceAlertSettings}
                handler={handleIcpAlertSave}
                loading={loading}
              />
            ) : (
              <CyclesAllowanceAlert
                settings={lowCyclesAllowanceAlertSettings}
                handler={lowCyclesAllowanceAlertSaveHandler}
                loading={loading}
              />
            )
          ) : null}
          <MemoryAlerts
            settings={{
              notifyOnMemoryThresholdReached:
                notificationSettings?.notifyOnMemoryThresholdReached,
            }}
            handlers={{ save: handleMemoryAlertSave }}
            loading={loading}
          />
        </>
      </div>
    </SettingsPage>
  );
}

export function useMockDefaultSettingsProps(): DefaultSettingsProps {
  const [notificationsLoading, setNotificationsLoading] = React.useState(false);
  const [lowICPBalanceAlertLoading, setLowICPBalanceAlertLoading] =
    React.useState(false);
  const [lowCyclesAllowanceAlertLoading, setLowCyclesAllowanceAlertLoading] =
    React.useState(false);
  const [notifyOnMemoryThresholdReached, setNotifyOnMemoryThresholdReached] =
    React.useState(false);
  const [memoryAlertLoading, setMemoryAlertLoading] = React.useState(false);
  const topupAlertSaveHandler = React.useCallback(
    async (settings: NotificationSettings) => {
      setNotificationsLoading(true);
      await new Promise((res) => {
        setTimeout(res, 1000);
      });
      setNotificationsLoading(false);
      toast(
        `STORYBOOK: Saved notification settings: failure ${settings.notifyOnTopupFailure}, success ${settings.notifyOnTopupSuccess}`
      );
    },
    []
  );
  const lowICPBalanceAlertSaveHandler = React.useCallback(
    async (settings: { enabled: boolean; threshold: number }) => {
      setLowICPBalanceAlertLoading(true);
      await new Promise((res) => {
        setTimeout(res, 1000);
      });
      setLowICPBalanceAlertLoading(false);
      toast(
        `STORYBOOK: Saved ICP alert settings: ${settings.threshold}ICP, enabled ${settings.enabled}`
      );
    },
    []
  );
  const lowCyclesAllowanceAlertSaveHandler = React.useCallback(
    async (settings: { enabled: boolean; threshold: number }) => {
      setLowCyclesAllowanceAlertLoading(true);
      await new Promise((res) => {
        setTimeout(res, 1000);
      });
      setLowCyclesAllowanceAlertLoading(false);
      toast(
        `STORYBOOK: Saved cycles alert settings: ${settings.threshold} cycles, enabled ${settings.enabled}`
      );
    },
    []
  );
  const memoryAlertSaveHandler = React.useCallback(async (enabled: boolean) => {
    setMemoryAlertLoading(true);
    await new Promise((res) => {
      setTimeout(res, 1000);
    });
    setMemoryAlertLoading(false);
    toast(`STORYBOOK: Saved memory alert settings: enabled ${enabled}`);
  }, []);

  const topupAlertSettings = {
    notifyOnTopupFailure: true,
    notifyOnTopupSuccess: true,
    notifyOnICPBelow: { e8s: 20_000_00 as unknown as bigint },
    notifyOnMemoryThresholdReached: false,
    // by default, keep these enabled as a security measure
    notifyOnReservedCyclesThresholdReached: true,
  };

  const lowICPBalanceAlertSettings = {
    enabled: false,
    threshold: 0.5,
  };

  const lowCyclesAllowanceAlertSettings = {
    enabled: false,
    threshold: 1,
  };

  return {
    notificationsLoading,
    lowICPBalanceAlertLoading,
    memoryAlertLoading,
    topupAlertSettings,
    lowICPBalanceAlertSettings,
    memoryAlertSaveHandler,
    lowICPBalanceAlertSaveHandler,
    memoryAlertSettings: { notifyOnMemoryThresholdReached },
    topupAlertSaveHandler,
    lowCyclesAllowanceAlertSettings,
    lowCyclesAllowanceAlertSaveHandler,
    lowCyclesAllowanceAlertLoading,
  };
}

interface ProjectsSettingsProps {}

export function ProjectsSettings(
  props: ProjectsSettingsProps & Omit<SettingsProps, "title">
) {
  const { mutateAsync: updateCanisterProject } = useCanisterProjectMutation();
  const { mutateAsync: renameProject } = useRenameProjectMutation();
  const { mutateAsync: deleteProject } = useDeleteProjectMutation();

  const { data: canisters } = useCanistersQuery();
  const { data: projects } = useProjectsQuery();

  const handleRenameProject = async (
    projectName: string,
    newProjectName: string
  ) => {
    await renameProject({ projectName, newProjectName });
  };

  const handleDeleteProject = async (projectName: string) => {
    await deleteProject({ projectName });
  };

  const onDragEnd: OnDragEndResponder = async (result) => {
    const canister = canisters?.find(
      ([p]) => p.toText() === result.draggableId
    )?.[1];
    const project = result.destination?.droppableId;

    if (!canister || !project) return;

    await updateCanisterProject({
      canisterId: canister.id,
      projectName: project === "No Project" ? undefined : project,
    });
  };

  const canistersByProject = canisters?.reduce((acc, [p, c, s, m]) => {
    const metadata = mapOptional(m);
    const project = mapOptional(metadata?.projectName ?? []) || "No Project";
    if (!acc.has(project)) {
      acc.set(project, new Set());
    }
    acc.get(project)?.add(c);
    return acc;
  }, new Map<string, Set<CanisterConfig>>(projects?.map((p) => [p.name, new Set()])));

  const noProject = canistersByProject?.get("No Project");

  const { open } = useCreateProjectDialogStore();

  return (
    <SettingsPage
      {...props}
      title="Settings"
      className="lg:max-w-none pt-0 md:pt-0 user-select-none"
      headChildren={
        <Button variant="secondary" onClick={open}>
          Add Project
        </Button>
      }
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex gap-4 h-full">
          <div className="flex flex-col gap-4 w-1/2 h-full pt-2 md:pt-4">
            {[...(canistersByProject?.entries() ?? [])]
              .filter(([p]) => p !== "No Project")
              .map(([project, cans]) => (
                <Card className="">
                  <CardHeader className="flex flex-row justify-between gap-2 py-1 pr-1 pl-4 items-center border-b space-y-0">
                    <div className="font-bold">{project}</div>
                    <ProjectDropdownMenu
                      project={project}
                      handleDeleteProject={handleDeleteProject}
                      handleRenameProject={handleRenameProject}
                    />
                  </CardHeader>
                  <CardContent className="p-0 m-0">
                    <ProjectDroppable
                      canisters={[...cans.values()]}
                      project={project}
                    />
                  </CardContent>
                </Card>
              ))}

            <Button
              variant="outline"
              className="flex gap-1 w-full"
              onClick={open}
            >
              Create New Project
            </Button>
            <CreateProjectDialog />
          </div>
          <div className="w-1/2 border-l h-full pt-2 md:pt-4 relative">
            <CardHeader className="flex flex-row justify-between gap-2 py-3 border-b">
              <div className="font-bold">No Project</div>
            </CardHeader>
            <CardContent className="p-0 m-0 sticky top-0">
              <ProjectDroppable
                canisters={[...(noProject?.values() ?? [])]}
                project="No Project"
              />
            </CardContent>
          </div>
        </div>
      </DragDropContext>
    </SettingsPage>
  );
}

function ProjectDroppable({
  project,
  canisters,
}: {
  project: string;
  canisters: CanisterConfig[];
}) {
  const getDroppableStyle = (style: DraggableStyle) => ({});
  return (
    <Droppable droppableId={project}>
      {(droppable, snapshot) => (
        <div
          ref={droppable.innerRef}
          {...droppable.droppableProps}
          className={cn(
            "flex flex-col w-full h-full p-4 pb-2",
            snapshot.isDraggingOver && "bg-neutral-200 dark:bg-neutral-900"
          )}
        >
          {canisters.map((canister, index) => (
            <Draggable
              key={canister.id.toText()}
              draggableId={canister.id.toText()}
              index={index}
            >
              {(draggable) => (
                <div
                  ref={draggable.innerRef}
                  {...draggable.draggableProps}
                  {...draggable.dragHandleProps}
                >
                  <Card
                    key={canister.name}
                    className="h-10 flex items-center px-2 gap-2 cursor-move mb-2 text-sm"
                  >
                    <GripVertical
                      size="16px"
                      className="text-neutral-400 dark:text-neutral-700"
                    />
                    {canister.name}
                  </Card>
                </div>
              )}
            </Draggable>
          ))}
          {droppable.placeholder}
          {canisters.length === 0 && <div>No canisters</div>}
        </div>
      )}
    </Droppable>
  );
}

function ProjectDropdownMenu({
  project,
  handleDeleteProject,
  handleRenameProject,
}: {
  project: string;
  handleDeleteProject: (project: string) => Promise<void>;
  handleRenameProject: (project: string, newName: string) => Promise<void>;
}) {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [dialogRenameOpen, setDialogRenameOpen] = React.useState(false);
  const [dialogDeleteOpen, setDialogDeleteOpen] = React.useState(false);
  const { TransferProjectDialog: TransferDialog, openTransferProject } =
    useTransferProjectDialog();

  const closePopover = () => setPopoverOpen(false);
  const openRenameDialog = () => setDialogRenameOpen(true);
  const openDeleteDialog = () => setDialogDeleteOpen(true);

  return (
    <>
      <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
        <PopoverTrigger asChild>
          <Button variant="ghost" size="icon">
            <Settings size="16px" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandList>
              <CommandGroup>
                <div
                  className="w-full"
                  onClick={() => {
                    closePopover();
                    openRenameDialog();
                  }}
                >
                  <CommandItem>
                    <PencilIcon className="mr-2 h-4 w-4" />
                    Rename Project
                  </CommandItem>
                </div>
                <div
                  className="w-full"
                  onClick={() => {
                    closePopover();
                    openDeleteDialog();
                  }}
                >
                  <CommandItem>
                    <TrashIcon className="mr-2 h-4 w-4" />
                    Delete Project
                  </CommandItem>
                </div>
                <div
                  className="w-full"
                  onClick={() => {
                    closePopover();
                    openTransferProject({ name: project });
                  }}
                >
                  <CommandItem>
                    <ArrowUpRightSquare className="mr-2 h-4 w-4" />
                    Transfer Project
                  </CommandItem>
                </div>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <Dialog open={dialogDeleteOpen} onOpenChange={setDialogDeleteOpen}>
        <DeleteProjectDialog
          project={project}
          handleDeleteProject={handleDeleteProject}
          handleClose={() => setDialogDeleteOpen(false)}
        />
      </Dialog>
      <Dialog open={dialogRenameOpen} onOpenChange={setDialogRenameOpen}>
        <RenameProjectDialog
          project={project}
          handleRenameProject={handleRenameProject}
          handleClose={() => setDialogRenameOpen(false)}
        />
      </Dialog>
      <TransferDialog />
    </>
  );
}

function RenameProjectDialog({
  project,
  handleRenameProject,
  handleClose,
}: {
  project: string;
  handleRenameProject: (project: string, newName: string) => Promise<void>;
  handleClose: () => void;
}) {
  const [newName, setNewName] = React.useState("");
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Rename Project</DialogTitle>
        <DialogDescription>
          Assign a new name to the "{project}" project.
        </DialogDescription>
      </DialogHeader>
      <form
        onSubmit={(e) => {
          handleRenameProject(project, newName);
          handleClose();
          e.preventDefault();
        }}
      >
        <div>
          <div className="space-y-4 py-2 pb-4">
            <div className="space-y-2">
              <Label htmlFor="name">Project name</Label>
              <Input
                id="name"
                placeholder="My excellent project"
                value={newName}
                onChange={(e) => setNewName(e.currentTarget.value)}
              />
            </div>
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" type="button">
              Cancel
            </Button>
          </DialogClose>
          <Button type="submit">Continue</Button>
        </DialogFooter>
      </form>
    </DialogContent>
  );
}

function DeleteProjectDialog({
  project,
  handleDeleteProject,
  handleClose,
}: {
  project: string;
  handleDeleteProject: (project: string) => Promise<void>;
  handleClose: () => void;
}) {
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Delete Project</DialogTitle>
        <DialogDescription>
          No canisters will be deleted. Are you sure you want to delete the "
          {project}" project?
        </DialogDescription>
      </DialogHeader>
      <form
        onSubmit={(e) => {
          handleDeleteProject(project);
          handleClose();
          e.preventDefault();
        }}
      >
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" type="button">
              Cancel
            </Button>
          </DialogClose>
          <Button type="submit">Continue</Button>
        </DialogFooter>
      </form>
    </DialogContent>
  );
}

export function ProfileSettings(props: Omit<SettingsProps, "title">) {
  const customer = useCustomerMetadataQuery();
  const team = useCurrentTeamQuery();

  return (
    <SettingsPage {...props} title="Settings" className="flex flex-col gap-8">
      <div className="flex flex-col-reverse md:grid grid-cols-[1fr,300px] gap-8">
        <Card>
          <CardHeader></CardHeader>
          <CardContent>
            <CustomerMetadataForm />
          </CardContent>
        </Card>
        <Card>
          <CardHeader></CardHeader>
          <CardContent className="flex flex-col items-center justify-center h-full gap-4 text-center">
            <Avatar className="h-24 w-24" radius="rounded-full">
              <AvatarImage src={customer.data?.logoUrl} />
              <AvatarFallback>
                <Hashatar
                  name={customer.data?.username || "undefined"}
                  radius="rounded-full"
                />
              </AvatarFallback>
            </Avatar>
            {customer.isLoading ? (
              <>
                <Skeleton className="w-24 h-7" />
                <Skeleton className="w-32 h-5" />
              </>
            ) : (
              <>
                <div className="text-xl">{customer.data?.displayName}</div>
                <div className="text-sm text-muted-foreground">
                  {customer.data?.username}
                </div>
              </>
            )}
          </CardContent>
        </Card>
      </div>
      {team.data && (
        <Card>
          <CardHeader>Delete Team</CardHeader>
          <CardContent className="text-sm">
            The team will be permanently deleted. This action cannot be undone.
          </CardContent>
          <CardFooter>
            <Dialog>
              <DeleteTeamDialog />
              <DialogTrigger asChild>
                <Button variant="outline">Delete Team</Button>
              </DialogTrigger>
            </Dialog>
          </CardFooter>
        </Card>
      )}
    </SettingsPage>
  );
}

function DeleteTeamDialog() {
  const { mutate, isPending, isSuccess } = useTeamDeleteMutation();

  if (isSuccess) return <Navigate to="/app" />;

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Delete Team</DialogTitle>
        <DialogDescription>
          Are you sure you want to delete this team?
        </DialogDescription>
      </DialogHeader>
      <form
        onSubmit={(e) => {
          mutate();
          e.preventDefault();
        }}
      >
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" type="button">
              Cancel
            </Button>
          </DialogClose>
          <Button type="submit" loading={isPending} disabled={isPending}>
            Continue
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  );
}

export function CreateTeamDialog({ handleClose }: { handleClose: () => void }) {
  const navigate = useNavigate();

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Create Team</DialogTitle>
        <DialogDescription>
          Create a new team to collaborate with others.
        </DialogDescription>
      </DialogHeader>
      <TeamCreationForm
        done={(r) => {
          handleClose();
          navigate(`/app/team/${r.username}`);
        }}
      />
    </DialogContent>
  );
}

interface MembersSettingsProps {}

function DisplayNameCell({ row }: { row: Row<Member> }) {
  return (
    <div className="flex items-center gap-3">
      <Avatar className="h-12 w-12" radius="rounded-full">
        <AvatarImage src={row.original.logoUrl} />
        <AvatarFallback>
          <Hashatar
            name={row.original.principal.toString()}
            radius="rounded-full"
          />
        </AvatarFallback>
      </Avatar>
      {row.original.displayName}
    </div>
  );
}

function RoleCell({ row }: { row: Row<Member> }) {
  const team = useCurrentTeamQuery();
  const members = useTeamMembersQuery(team.data?.principal);
  const { principal } = useIdp();

  const { mutate } = useTeamMemberPermissionMutation();

  const [value, setValue] = React.useState(row.original.role);

  const isSelf = row.original.principal.toText() === principal.toText();
  const isAdmin =
    members.data?.find(({ principal: p }) => p.toText() === principal.toText())
      ?.role === "admin";

  if (!isAdmin || isSelf)
    return <span className="capitalize">{row.original.role}</span>;

  return (
    <Select
      value={value}
      onValueChange={(e) => {
        setValue(e as "admin" | "member");
        mutate({
          member: row.original.principal,
          role: e as "admin" | "member",
        });
      }}
    >
      <SelectTrigger className="w-40">
        <SelectValue />
      </SelectTrigger>
      <SelectContent position="popper">
        <SelectItem value="admin">
          Admin
          {/* <div className="text-xs text-muted-foreground">
            Has full control of the team
          </div> */}
        </SelectItem>
        <SelectItem value="member">
          Member
          {/* <div className="text-xs text-muted-foreground">
            Can do everything except manage team members and billing
          </div> */}
        </SelectItem>
      </SelectContent>
    </Select>
  );
}

function DeleteMemberDialog({ member }: { member: Principal }) {
  const { mutate } = useTeamMemberRemovalMutation();
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Remove Member</DialogTitle>
        <DialogDescription>
          Are you sure you want to remove this member?
        </DialogDescription>
      </DialogHeader>
      <form
        onSubmit={(e) => {
          mutate({ member });
          e.preventDefault();
        }}
      >
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" type="button">
              Cancel
            </Button>
          </DialogClose>
          <Button type="submit">Continue</Button>
        </DialogFooter>
      </form>
    </DialogContent>
  );
}

function ActionsCell({ row }: { row: Row<Member> }) {
  const team = useCurrentTeamQuery();
  const members = useTeamMembersQuery(team.data?.principal);
  const { principal } = useIdp();

  const isAdmin =
    members.data?.find(({ principal: p }) => p.toText() === principal.toText())
      ?.role === "admin";

  const isSelf = row.original.principal.toText() === principal.toText();

  return (
    isAdmin &&
    !isSelf && (
      <div aria-labelledby={`header-3`}>
        <Dialog>
          <DeleteMemberDialog member={row.original.principal} />
          <DialogTrigger asChild>
            <Button variant="outline" size="icon">
              <Trash2Icon size="16" />
            </Button>
          </DialogTrigger>
        </Dialog>
      </div>
    )
  );
}

const columns: ColumnDef<Member>[] = [
  {
    id: "Display Name",
    header: DataTableColumnHeader,
    accessorKey: "displayname",
    cell: ({ row }) => <DisplayNameCell row={row} />,
  },
  { id: "Username", header: DataTableColumnHeader, accessorKey: "username" },
  {
    id: "Principal",
    header: DataTableColumnHeader,
    accessorKey: "id",
    cell: ({ row }) => (
      <PrincipalAbbr>{row.original.principal.toText()}</PrincipalAbbr>
    ),
  },
  {
    id: "Role",
    header: DataTableColumnHeader,
    accessorKey: "role",
    cell: ({ row }) => <RoleCell row={row} />,
  },
  {
    id: "Actions",
    header: DataTableColumnHeader,
    cell: ({ row }) => <ActionsCell row={row} />,
  },
];

export function MemberTable() {
  const team = useCurrentTeamQuery();
  const route = useRoute();
  const { data, isLoading, isPaused } = useTeamMembersQuery(
    team.data?.principal
  );

  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "Role", desc: false },
  ]);
  const table = useReactTable<Member>({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: { sorting },
    initialState: {},
  });

  if (team.isFetched && !team.data) return <Navigate to={route("/settings")} />;

  if (isLoading || isPaused) return <TableSkeleton />;

  return (
    <>
      <Table className="bg-background">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className="group"
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}

export function AddMemberDialog() {
  const { mutateAsync, isPending } = useTeamAddMemberMutation();

  const [open, setOpen] = React.useState(false);

  const [principal, setPrincipal] = React.useState("");
  const [role, setRole] = React.useState<Role>("member");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button>Add Member</Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Member</DialogTitle>
          <DialogDescription>
            To add someone to you team, ask them to sign in and copy their
            principal ID.{" "}
            <a
              href="https://docs.cycleops.dev/docs/basics/teams-management"
              target="_blank"
            >
              Need help adding team members?
            </a>
          </DialogDescription>
        </DialogHeader>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            try {
              await mutateAsync({
                member: Principal.fromText(principal),
                role,
              });
              setOpen(false);
            } catch (err) {
              console.error(err);
              if (err instanceof Error) toast.error(err.message);
              else toast.error("Failed to add member");
            }
          }}
        >
          <div>
            <div className="space-y-4 py-2 pb-4">
              <div className="space-y-2">
                <Label htmlFor="principal">Principal</Label>
                <Input
                  id="principal"
                  value={principal}
                  onChange={(e) => setPrincipal(e.currentTarget.value)}
                  placeholder=""
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="role">Role</Label>
                <Select value={role} onValueChange={(e) => setRole(e as Role)}>
                  <SelectTrigger className="w-40">
                    <SelectValue placeholder="Select a role" />
                  </SelectTrigger>
                  <SelectContent position="popper">
                    <SelectItem value="admin">Admin</SelectItem>
                    <SelectItem value="member">Member</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" type="button">
                Cancel
              </Button>
            </DialogClose>
            <Button type="submit" loading={isPending} disabled={isPending}>
              Continue
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export function MembersSettings(
  props: MembersSettingsProps & Omit<SettingsProps, "title">
) {
  const team = useCurrentTeamQuery();
  const members = useTeamMembersQuery(team.data?.principal);
  const { principal } = useIdp();

  const isAdmin =
    members.data?.find(({ principal: p }) => p.toText() === principal.toText())
      ?.role === "admin";

  // in storybook you'll need to select a Team first or you'll be given a blank screen
  // TODO mock router in storybook
  if (!team || !principal) return <Navigate to="/app/settings" />;

  return (
    <SettingsPage
      {...props}
      title="Settings"
      headChildren={isAdmin && <AddMemberDialog />}
    >
      <Card>
        <MemberTable />
      </Card>
    </SettingsPage>
  );
}

export function ExperimentsSettings() {
  return (
    <SettingsPage title="Experiments">
      <ExperimentalFeatures />
    </SettingsPage>
  );
}
