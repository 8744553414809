import { Principal } from "@dfinity/principal";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { SelectedCanisterCommandPrompt } from "@/components/canister-list/commands";
import { DataTable } from "@/components/canister-list/table/table";
import { useRoute } from "@/hooks/queries/team";
import { cn } from "@/lib/ui-utils";

export default function CanisterTable({ className }: { className?: string }) {
  const navigate = useNavigate();
  const route = useRoute();

  const onRowClick = useCallback(
    (id: Principal) => {
      navigate(route(`/canisters/${id}`));
    },
    [route]
  );

  return (
    <>
      <div className={cn("relative bg-table h-[100dvh]", className)}>
        <DataTable className="" onRowClick={onRowClick} />
      </div>
      <SelectedCanisterCommandPrompt className="absolute bottom-0" />
    </>
  );
}
