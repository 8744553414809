import { AddMemberDialog, MemberTable } from "@/components/pages/settings";
import { useCurrentTeamQuery, useTeamMembersQuery } from "@/hooks/queries/team";
import { useIdp } from "@/state/stores/idp";

import SettingsPage from "./settings";

export default function MembersPage() {
  const team = useCurrentTeamQuery();
  const members = useTeamMembersQuery(team.data?.principal);
  const { principal } = useIdp();

  const isAdmin =
    members.data?.find(({ principal: p }) => p.toText() === principal.toText())
      ?.role === "admin";

  return (
    <SettingsPage title="Members" headChildren={isAdmin && <AddMemberDialog />}>
      <div className="border border-border/75 flex flex-1 flex-col gap-4 pt-0 relative rounded-lg overflow-hidden bg-table">
        <div className="h-auto">
          <MemberTable />
        </div>
      </div>
    </SettingsPage>
  );
}
