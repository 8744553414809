import { RouterProvider } from "react-router-dom";
import { useStore } from "zustand";

import idp from "@/state/stores/idp";

import LocalDataMocks from "./components/local-data-mocks";
import LoadingPage from "./components/pages/loading";
import { useNewUIFlag } from "./hooks/use-flags";
import { MainRouter, NewUIRouter } from "./routes/app";
import { EmailVerificationRouter } from "./routes/email-verification";

export default function App() {
  const newUIFlag = useNewUIFlag();
  const router = newUIFlag === true ? NewUIRouter : MainRouter;
  // Since the IDP store is initialized asynchronously, we wait for it before rending the app, so that the app can be hydrated with a complete IDP state.
  const { initialized } = useStore(idp);
  if (!initialized || newUIFlag === undefined) return <LoadingPage />;

  switch (import.meta.env.CYOPS_APP) {
    case "email-verification":
      return <RouterProvider router={EmailVerificationRouter} />;
    default:
      return (
        <>
          <LocalDataMocks />
          <RouterProvider router={router} />
        </>
      );
  }
}
