import { useLocation, useParams } from "react-router-dom";

import CanisterRule from "@/components/canister-rule";
import {
  CanisterAdvancedSettingsContent,
  CanisterAlertCard,
} from "@/components/pages/canister-detail";
import { ResponsiveSidebar } from "@/components/ui/responsive-sidebar";

import { CanisterGeneralSettings } from "./canister/settings/general";
import CanisterOrganizationPage from "./canister-organization";

export default function CanisterSettingsPage() {
  return (
    <CanisterSettingsLayout>
      <CanisterGeneralSettings />
    </CanisterSettingsLayout>
  );
}

export function CanisterRulePage() {
  return (
    <CanisterSettingsLayout>
      <CanisterRule />
    </CanisterSettingsLayout>
  );
}

export function CanisterAlertPage() {
  return (
    <CanisterSettingsLayout>
      <CanisterAlertCard />
    </CanisterSettingsLayout>
  );
}

export function CanisterAdvancedPage() {
  return (
    <CanisterSettingsLayout>
      <CanisterAdvancedSettingsContent />
    </CanisterSettingsLayout>
  );
}

export { CanisterOrganizationPage };

export function CanisterSettingsLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <>
      <div className="flex flex-col lg:flex-row bg-table relative p-4 lg:p-2 min-h-full">
        <CanisterSettingsMenu />
        <div className="flex-1 lg:p-2 lg:pl-0">{children}</div>
      </div>
    </>
  );
}

const menuItems = (id?: string) => [
  {
    label: "General",
    path: () => `/canisters/${id}/settings`,
  },
  {
    label: "Organization",
    path: () => `/canisters/${id}/settings/organization`,
  },
  {
    label: "Top-Up Automation",
    path: () => `/canisters/${id}/settings/rule`,
  },
  {
    label: "Alerts",
    path: () => `/canisters/${id}/settings/alerts`,
  },
  {
    label: "Advanced",
    path: () => `/canisters/${id}/settings/advanced`,
  },
];

function CanisterSettingsMenu() {
  const { canisterId: canisterIdString } = useParams();
  return <ResponsiveSidebar items={menuItems(canisterIdString)} />;
}
