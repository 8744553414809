import { Check, PlusIcon } from "lucide-react";
import React from "react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

type FilterOption = any;

type FilterComponentProps = {
  filter: FilterOption;
  onChange: (updatedFilter: FilterOption) => void;
  onRemove: () => void;
  comparators: string[];
  values: string[];
};

const FilterComponent: React.FC<FilterComponentProps> = ({
  filter,
  onChange,
  comparators,
  values,
  onRemove,
}) => {
  const handleComparatorChange = (comparator: any) =>
    onChange({ ...filter, comparator });
  const handleValueChange = (value: string) => onChange({ ...filter, value });

  if (filter.type === "multiselect" && filter.values.length === 0) return null;

  return (
    <div className="flex items-center bg-muted/50 rounded-md divide-background text-secondary-foreground text-xs divide-x h-6 my-auto text-nowrap">
      {/* Field label */}
      <div className="flex gap-1 items-center h-full transition-colors px-2 cursor-default rounded-l-md">
        {/* {<filter.icon className="w-3" />} */}
        {filter.field}
      </div>

      {/* Comparator Dropdown */}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="gap-2 h-full flex items-center hover:bg-muted transition-colors cursor-pointer px-2">
            {filter.comparator}
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {comparators.map((comparator) => (
            <DropdownMenuItem
              key={comparator}
              onSelect={() => handleComparatorChange(comparator)}
            >
              {comparator}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>

      {/* Value Dropdown */}
      {filter.type === "select" ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="h-full flex items-center hover:bg-muted transition-colors cursor-pointer px-2 capitalize">
              {filter.value}
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {values.map((value) => (
              <DropdownMenuItem
                key={value}
                onSelect={() => handleValueChange(value)}
                className="capitalize"
              >
                {value}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      ) : filter.type === "multiselect" ? (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="h-full flex items-center hover:bg-muted transition-colors cursor-pointer px-2 capitalize">
              {filter.values.length} selected
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {filter.options.map((value: string) => (
              <DropdownMenuItem
                key={value}
                onSelect={() => {
                  let existingValues = Array.isArray(filter.values)
                    ? filter.values
                    : [];

                  if (!existingValues.includes(value))
                    existingValues.push(value);
                  else
                    existingValues = existingValues.filter(
                      (v: string) => v !== value
                    );

                  const newFilter = { ...filter, values: existingValues };

                  onChange(newFilter);
                }}
                className="capitalize cursor-pointer"
              >
                {filter.values.includes(value) ? <Check /> : null}
                {value}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <div className="flex items-center">
          <input
            onChange={(e) => handleValueChange(e.target.value)}
            type="number"
            min={0}
            value={filter.value}
            className="h-full flex items-center hover:bg-muted transition-colors cursor-pointer px-2 capitalize bg-transparent outline-0 w-10 text-center"
          />
          {filter.unit && <span className="pr-2">{filter.unit}</span>}
        </div>
      )}

      <div
        onClick={onRemove}
        className="h-full flex items-center hover:bg-muted transition-colors cursor-pointer px-2 capitalize rounded-r-md"
      >
        <PlusIcon className="w-4 rotate-45" />
      </div>
    </div>
  );
};
export { FilterComponent };

// Example usage
// const Example = () => {
//   const [filters, setFilters] = React.useState<FilterOption[]>([
//     { field: "", comparator: "", value: "" },
//   ]);

//   const handleFilterChange = (index: number, updatedFilter: FilterOption) => {
//     const updatedFilters = [...filters];
//     updatedFilters[index] = updatedFilter;
//     setFilters(updatedFilters);
//   };

//   return (
//     <div className="space-y-4">
//      c
//       <Button onClick={() => setFilters([...filters, { field: "", comparator: "", value: "" }])}>
//         Add Filter
//       </Button>
//     </div>
//   );
// };

// export default Example;
