import { Principal } from "@dfinity/principal";
import { Trash2 } from "lucide-react";
import { toast } from "sonner";
import { create } from "zustand";

import PrincipalAbbr from "@/components/principal-abbr";
import { useBatchCanisterDeleteMutation } from "@/hooks/queries/canisters";
import { useCanisterTable } from "@/hooks/stores/canister-table-store";

import { Button } from "../../ui/button";
import {
  CommandDialog,
  CommandGroup,
  CommandItem,
  CommandList,
} from "../../ui/command";
import { useSelectedCanisters } from "../table/context-menu";
import { CanisterCount } from "./canister-count";

interface DeleteCommandState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  canisterId?: Principal;
  setCanisterId: (canisterId?: Principal) => void;
}

export const useDeleteCommand = create<DeleteCommandState>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  setCanisterId: (canisterId) => set({ canisterId }),
}));

export function DeleteCommandDialog() {
  const { isOpen, close, canisterId, setCanisterId } = useDeleteCommand();
  const selectedCanisters = useSelectedCanisters();
  const mutation = useBatchCanisterDeleteMutation();
  const table = useCanisterTable();

  const canisterIds = selectedCanisters.length
    ? selectedCanisters
    : [canisterId!];

  const canisterCount = canisterIds.length;

  function handleDelete() {
    const toastId = toast.loading(
      `Deleting ${canisterCount} canister${canisterCount !== 1 ? "s" : ""}...`
    );

    mutation.mutate(
      { canisterIds },
      {
        onSuccess: () => {
          toast.dismiss(toastId);
          table.setRowSelection({});
          setCanisterId(undefined);
        },
        onError: (error) => {
          toast.error("Failed to delete canisters", {
            description: error.message,
            id: toastId,
          });
        },
      }
    );
    close();
  }

  function handleOpenChange(open: boolean) {
    if (!open && mutation.isPending) {
      // If closing while deletion is in progress, show the loading toast
      toast.loading(
        `Deleting ${canisterCount} canister${canisterCount !== 1 ? "s" : ""}...`
      );
    }
    close();
  }

  return (
    <CommandDialog open={isOpen} onOpenChange={handleOpenChange}>
      <CanisterCount count={canisterCount} />
      <CommandList>
        <CommandGroup>
          <div className="p-4 flex flex-col gap-4">
            <div className="text-sm">
              Are you sure you want to delete{" "}
              {canisterCount > 1 ? (
                `${canisterCount} canisters`
              ) : (
                <PrincipalAbbr>{canisterIds[0]?.toText()}</PrincipalAbbr>
              )}
              ? This action cannot be undone.
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleDelete();
              }}
              className="flex gap-2 justify-end"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                }
              }}
            >
              <Button
                type="button"
                variant="ghost"
                onClick={close}
                disabled={mutation.isPending}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="destructive"
                className="gap-2"
                disabled={mutation.isPending}
              >
                <Trash2 className="w-4 h-4" />
                {mutation.isPending ? "Deleting..." : "Delete"}
              </Button>
            </form>
          </div>
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  );
}
