"use client";

import { ChevronsUpDown, Plus } from "lucide-react";
import * as React from "react";
import { useParams } from "react-router-dom";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import {
  useCustomerMetadataQuery,
  useIndividualMetadataQuery,
} from "@/hooks/queries/customer";
import {
  AppLink,
  route,
  useCallerTeamsQuery,
  useCurrentAppPath,
} from "@/hooks/queries/team";

import { CreateTeamDialog } from "./pages/settings";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Dialog } from "./ui/dialog";

export interface Team {
  id: string;
  name: string;
  logo?: string;
}

export function TeamAvatar({ logo, name }: { logo?: string; name?: string }) {
  return (
    <Avatar className="h-8 w-8 rounded-lg">
      <AvatarImage src={logo} alt={name} />
      <AvatarFallback className="rounded-lg uppercase">
        {name?.slice(0, 1)}
      </AvatarFallback>
    </Avatar>
  );
}

export function TeamSwitcher() {
  const userQuery = useIndividualMetadataQuery();
  const activeAccountQuery = useCustomerMetadataQuery();
  const teamsQuery = useCallerTeamsQuery();
  const appPath = useCurrentAppPath();

  const [createTeamDialogOpen, setCreateTeamDialogOpen] = React.useState(false);

  const { isMobile } = useSidebar();

  const user = userQuery.data
    ? ({
        id: userQuery.data.username,
        name: "My Account",
        logo: userQuery.data.logoUrl,
      } satisfies Team)
    : undefined;

  const active = activeAccountQuery.data
    ? ({
        id: activeAccountQuery.data.username,
        name: activeAccountQuery.data.displayName || "My Account",
        logo: activeAccountQuery.data.logoUrl,
      } satisfies Team)
    : undefined;

  const teams =
    teamsQuery.data?.map((team) => ({
      id: team.username,
      name: team.displayName,
      logo: team.logoUrl,
    })) || [];

  return (
    <>
      <SidebarMenu>
        <SidebarMenuItem>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <SidebarMenuButton
                size="lg"
                className="data-[state=open]:bg-muted/50 data-[state=open]:ring-1 data-[state=open]:ring-border/80 hover:bg-muted transition-colors duration-100"
                tooltip={`Active: ${active?.name}`}
              >
                <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                  <TeamAvatar name={active?.name} logo={active?.logo} />
                </div>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">
                    {active?.id === user?.id ? "My Account" : active?.name}
                  </span>
                </div>
                <ChevronsUpDown className="ml-auto" />
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
              align="start"
              side={isMobile ? "bottom" : "bottom"}
              sideOffset={5}
            >
              <DropdownMenuLabel className="text-xs text-muted-foreground">
                Personal
              </DropdownMenuLabel>
              <AppLink noProcess to={route(appPath)}>
                <DropdownMenuItem
                  className={`gap-2 p-2 transition-colors duration-100 ${
                    !active || active.id === user?.id
                      ? "bg-muted/50 ring-1 ring-border/80"
                      : "hover:bg-muted"
                  }`}
                >
                  <div className="flex size-6 items-center justify-center rounded-sm border">
                    <TeamAvatar name={user?.name} logo={user?.logo} />
                  </div>
                  {user?.name}
                </DropdownMenuItem>
              </AppLink>
              {teams.length > 0 && (
                <>
                  <DropdownMenuSeparator />
                  <DropdownMenuLabel className="text-xs text-muted-foreground">
                    Teams
                  </DropdownMenuLabel>
                  {teams.map((team, index) => (
                    <AppLink
                      noProcess
                      className="plain"
                      to={route(appPath, team.id)}
                      key={team.name}
                    >
                      <DropdownMenuItem
                        className={`gap-2 p-2 transition-colors duration-100 ${
                          active?.id === team.id
                            ? "bg-muted/50 ring-1 ring-border/80"
                            : "hover:bg-muted"
                        }`}
                      >
                        <div className="flex size-6 items-center justify-center rounded-sm border">
                          <TeamAvatar name={team.name} logo={team.logo} />
                        </div>
                        {team.name}
                      </DropdownMenuItem>
                    </AppLink>
                  ))}
                </>
              )}
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="gap-2 p-2 hover:bg-muted transition-colors duration-100"
                onClick={() => setCreateTeamDialogOpen(true)}
              >
                <div className="flex size-6 items-center justify-center rounded-md border bg-background">
                  <Plus className="size-4" />
                </div>
                <div className="font-medium text-muted-foreground">
                  Add team
                </div>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarMenuItem>
      </SidebarMenu>
      <Dialog
        open={createTeamDialogOpen}
        onOpenChange={setCreateTeamDialogOpen}
      >
        <CreateTeamDialog handleClose={() => setCreateTeamDialogOpen(false)} />
      </Dialog>
    </>
  );
}
