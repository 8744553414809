import { ComponentPropsWithoutRef } from "react";

import { cn } from "@/lib/ui-utils";

export interface Props extends ComponentPropsWithoutRef<"div"> {
  status: "healthy" | "low balance" | "frozen" | "pending";
}

export default function Blip({ className, status, ...props }: Props) {
  return (
    <div
      className={cn(
        "w-3 h-3 rounded-full",
        status === "healthy" &&
          "bg-green-500 border-[1.5px] border-green-400 animate-glow-healthy",
        status === "low balance" &&
          "bg-orange-500 border-[1.5px] border-orange-400 animate-glow-unhealthy",
        status === "frozen" &&
          "bg-pink-600 border-[1.5px] border-pink-500 animate-glow-frozen",
        status === "pending" &&
          "bg-blue-500 border-[1.5px] border-blue-500 animate-glow-pending",
        className
      )}
      {...props}
    />
  );
}
