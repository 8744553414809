import { useState } from "react";
import { useNavigate, useRouteError } from "react-router-dom";

import LayoutFocus, { FocusTopbar } from "@/components/layout/layout-focus";
import { SiteBreadcrumb } from "@/components/site-breadcrumb";
import { Support } from "@/components/support";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { AppLink } from "@/hooks/queries/team";

import Error404, { NotFoundError } from "./error-404";

export default function Error500() {
  const navigate = useNavigate();
  const error = useRouteError() as Error;
  const [isOpen, setIsOpen] = useState(false);

  function handleDismiss() {
    navigate(window.location.pathname);
  }

  if (error instanceof NotFoundError) {
    return <Error404 />;
  }

  return (
    <LayoutFocus>
      <FocusTopbar>
        <SiteBreadcrumb items={[{ title: "500" }]} />
      </FocusTopbar>
      <div className="flex flex-col justify-center items-center gap-12 w-full h-full">
        <div className="flex flex-col items-center gap-4">
          <div className="text-5xl font-bold">Application error</div>
          <div className="text-lg text-center">
            An error occurred and developer has been notified. If you need help,{" "}
            <Support>
              <span className="underline cursor-pointer">
                please contact support.
              </span>
            </Support>
          </div>
          {error && (
            <Card>
              <Collapsible open={isOpen} onOpenChange={setIsOpen}>
                <CardHeader className="px-4 py-3 w-[600px] flex flex-row items-center justify-between space-y-0">
                  <CardTitle className="text-sm font-medium text-red-900 italic">
                    {error.message}
                  </CardTitle>
                  <CollapsibleTrigger asChild>
                    <Button size="sm" variant="outline" className="text-xs">
                      {isOpen ? "Hide" : "Details"}
                    </Button>
                  </CollapsibleTrigger>
                </CardHeader>
                <CollapsibleContent asChild>
                  <CardContent className="h-[200px] w-[600px] py-0 px-4 overflow-auto">
                    <pre className="text-xs font-mono whitespace-pre py-4">
                      {error.stack}
                    </pre>
                  </CardContent>
                </CollapsibleContent>
              </Collapsible>
            </Card>
          )}
        </div>
        <div className="flex flex-col items-center gap-2 w-full max-w-48">
          <Button
            className="w-full"
            size="lg"
            variant="default"
            onClick={handleDismiss}
          >
            Dismiss error
          </Button>
          <AppLink to="/" className="w-full">
            <Button variant="secondary" className="w-full" size="lg">
              Take me home
            </Button>
          </AppLink>
        </div>
      </div>
    </LayoutFocus>
  );
}
